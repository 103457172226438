// TODO: Replace h3 tag content with incoming text with the correct language

import React from 'react';
import NextNavLink from './../NextNavLink';

import { ARCHIVE_LAYOUTS } from '../../util/i18n';

const BlogTagsList = ({ tags, language, ...props }) => {
    return (
        <div className="widget widget_tag_cloud tag_cloud-1">
            <h3 className="cdb-sidearea-title">
                {ARCHIVE_LAYOUTS['tags'][language]}
            </h3>
            <div className="tagcloud">
                {tags.filter(tag => tag.count).map((tag) => (
                    <NextNavLink href={tag.uri}>
                        <a
                            key={tag.databaseId}
                            className={`tag-cloud-link tag-link-${tag.databaseId} tag-link-position-${tag.databaseId}`}
                            style={{ fontSize: '16.6153846154pt' }}
                            aria-label={`${tag.name} (${tag.count} items)`}
                        >
                            {tag.name}
                        </a>
                    </NextNavLink>
                ))}
            </div>
        </div>
    );
};

export default BlogTagsList;
