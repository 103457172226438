import React, { useState, useEffect } from 'react';
import Head from 'next/head';
import { decodeHTMLEntities } from '../../util/miscellaneous';

function CBSectionSubTitle({
    color = '#000',
    text,
    text_align = 'left',
    width = '100%',
    el_class
}) {
    return (
        <>
            <div
                className={`cdb-section-subtitle-holder cdb-section-subtitle-${text_align}`}
            >
                <p
                    className={`cdb-section-subtitle ${
                        el_class ? el_class : ''
                    }`}
                    style={{
                        color: color,
                        textAlign: text_align,
                        width: `${width}%`
                    }}
                >
                    {text}
                </p>
            </div>
        </>
    );
}

export default CBSectionSubTitle;
