import React, { useState } from 'react';
import Head from 'next/head';
import Lightbox from 'react-image-lightbox';

function VisualComposerSingleImage({ attr }) {
    const [isOpen, setIsOpen] = useState(false);
    const imageFile = attr.meta_properties.url
        ? {
              id: attr.meta_properties.id,
              mediaItemId: attr.meta_properties.id,
              mediaItemUrl: attr.meta_properties.url,
              mediaMediumItemUrl: attr.meta_properties.medium_url,
              title: attr.meta_properties.title,
              sizes: null,
              srcSet: null,
              caption: attr.meta_properties.caption
          }
        : {};
    let imageW, imageH;
    if (attr?.img_size) {
        imageW = attr?.img_size.split('x')[0];
        imageH = attr?.img_size.split('x')[1];
    }
    let img_size = attr.img_size ? attr.img_size : '';

    return (
        <>
            {attr.css ? (
                <Head>
                    <style type="text/css">{`
                    ${attr.css && attr.css}
                `}</style>
                </Head>
            ) : (
                ''
            )}
            <div
                id={attr.el_id ? attr.el_id : ''}
                className={`wpb_single_image wpb_content_element vc_align_${
                    attr.alignment ? attr.alignment : 'left'
                } ${attr.el_class ? attr.el_class : ''} ${
                    attr.css ? attr.css.split('{')[0].substring(1) : ''
                }`}
            >
                {attr.title && (
                    <h2 className="wpb_heading wpb_singleimage_heading">
                        {attr.title}
                    </h2>
                )}
                {attr.onclick === 'link_image' ? (
                    <figure className="wpb_wrapper vc_figure">
                        {isOpen && (
                            <>
                                <Lightbox
                                    mainSrc={imageFileSrc}
                                    onCloseRequest={() => setIsOpen(false)}
                                />
                            </>
                        )}
                        <div
                            className={`${
                                attr.style ? attr.style : ''
                            } vc_single_image-wrapper position-relative  vc_box_border_${
                                attr.border_color ? attr.border_color : 'grey'
                            }`}
                            onClick={() => setIsOpen(true)}
                        >
                            {attr.onclick === 'img_link_large' ||
                            attr.onclick === 'custom_link' ? (
                                <a
                                    target="_blank"
                                    className="stretched-link"
                                    aria-label={
                                        attr.title
                                            ? attr.title
                                            : imageFile.title
                                    }
                                    href={
                                        attr.link ||
                                        attr.custom_src ||
                                        imageFile.mediaItemUrl
                                    }
                                ></a>
                            ) : (
                                ''
                            )}

                            {attr.source === 'external_link' ? (
                                <img
                                    className="vc_single_image-img"
                                    src={attr.custom_src}
                                    width={imageW}
                                    height={imageH}
                                    alt={
                                        attr.caption ? attr.caption : attr.title
                                    }
                                />
                            ) : (
                                <img
                                    className="vc_single_image-img"
                                    src={
                                        img_size === 'medium'
                                            ? imageFile.mediaMediumItemUrl
                                            : imageFile.mediaItemUrl
                                    }
                                    width={imageW}
                                    height={imageH}
                                    alt={
                                        attr.title
                                            ? attr.title
                                            : imageFile.title
                                    }
                                />
                            )}
                        </div>
                        {attr.add_caption === 'yes' &&
                        imageFile.caption !== '' ? (
                            <figcaption
                                dangerouslySetInnerHTML={{
                                    __html: imageFile.caption
                                }}
                                className="vc_figure-caption"
                            />
                        ) : (
                            ''
                        )}
                        {attr.caption !== '' ? (
                            <figcaption
                                dangerouslySetInnerHTML={{
                                    __html: attr.caption
                                }}
                                className="vc_figure-caption"
                            />
                        ) : (
                            ''
                        )}
                    </figure>
                ) : (
                    <figure className="wpb_wrapper vc_figure">
                        <div
                            className={`${
                                attr.style ? attr.style : ''
                            } vc_single_image-wrapper position-relative  vc_box_border_${
                                attr.border_color ? attr.border_color : 'grey'
                            }`}
                        >
                            {attr.onclick === 'img_link_large' ||
                            attr.onclick === 'custom_link' ? (
                                <a
                                    aria-label={
                                        attr.title
                                            ? attr.title
                                            : imageFile.title
                                    }
                                    target="_blank"
                                    className="stretched-link"
                                    href={
                                        attr.link ||
                                        attr.custom_src ||
                                        imageFile.mediaItemUrl
                                    }
                                ></a>
                            ) : (
                                ''
                            )}

                            {attr.source === 'external_link' ? (
                                <img
                                    width={imageW}
                                    height={imageH}
                                    className="vc_single_image-img"
                                    src={attr.custom_src}
                                    alt={
                                        attr.caption ? attr.caption : attr.title
                                    }
                                />
                            ) : (
                                <img
                                    width={imageW}
                                    height={imageH}
                                    className="vc_single_image-img"
                                    src={
                                        img_size === 'medium'
                                            ? imageFile.mediaMediumItemUrl
                                            : imageFile.mediaItemUrl
                                    }
                                    alt={
                                        attr.title
                                            ? attr.title
                                            : imageFile.title
                                    }
                                />
                            )}
                        </div>
                        {attr.add_caption === 'yes' &&
                        imageFile.caption !== '' ? (
                            <figcaption
                                dangerouslySetInnerHTML={{
                                    __html: imageFile.caption
                                }}
                                className="vc_figure-caption"
                            />
                        ) : (
                            ''
                        )}
                        {attr.caption !== '' ? (
                            <figcaption
                                dangerouslySetInnerHTML={{
                                    __html: attr.caption
                                }}
                                className="vc_figure-caption"
                            />
                        ) : (
                            ''
                        )}
                    </figure>
                )}
            </div>
        </>
    );
}

export default VisualComposerSingleImage;
