import React, { useState, useEffect } from 'react';
import Head from 'next/head';
import VisualComposerTabsContent from './VisualComposerTabsContent';
import VisualComposerTabsOption from './VisualComposerTabsOption';

const VisualComposerTour = ({
    activeSection = 1,
    alignment = 'left',
    controlsSize,
    className = '',
    color = 'grey',
    id = '',
    gap = 0,
    shape = 'rounded',
    spacing = 1,
    style = 'classic',
    tabPosition = 'left',
    title = '',
    css,
    children,
    ...props
}) => {
    // States
    const [activeTab, setActiveTab] = useState(null);

    useEffect(() => {
        setActiveTab(children[activeSection - 1].props.tab_id);
    }, [activeSection]);

    return (
        <>
            {css ? (
                <Head>
                    <style>{css}</style>
                </Head>
            ) : (
                ''
            )}
            <div className="vc_tta-container">
                {title.length > 0 ? <h2>{title}</h2> : ''}
                <div
                    className={`vc_general vc_tta vc_tta-tabs vc_tta-color-${color} vc_tta-style-${style} vc_tta-shape-${shape} vc_tta-spacing-${spacing} ${gap > 0 ? `vc_tta-gap-${gap}` : ''} ${className} vc_tta-tabs-position-${tabPosition} vc_tta-controls-align-${alignment} ${
                        controlsSize
                            ? `vc_tta-controls-size-${controlsSize}`
                            : ''
                    } ${css ? css.split('{')[0].substring(1) : ''} `}
                    id={id}
                >
                    {tabPosition === 'left' ? (
                        <div className="vc_tta-tabs-container">
                            <ul className="vc_tta-tabs-list">
                                {children.map((child, i) => (
                                    <VisualComposerTabsOption
                                        {...child.props}
                                        key={child.props.tab_id}
                                        id={child.props.tab_id}
                                        title={child.props.title}
                                        active={
                                            activeTab === child.props.tab_id
                                        }
                                        activeHandler={setActiveTab}
                                    />
                                ))}
                            </ul>
                        </div>
                    ) : (
                        ''
                    )}

                    <div className="vc_tta-panels-container">
                        <div className="vc_tta-panels">
                            {children.map((child, i) => (
                                <VisualComposerTabsContent
                                    key={child.props.tab_id}
                                    id={child.props.tab_id}
                                    active={activeTab === child.props.tab_id}
                                    className={child.props.el_class}
                                >
                                    {child.props.children}
                                </VisualComposerTabsContent>
                            ))}
                        </div>
                    </div>

                    {tabPosition === 'right' ? (
                        <div className="vc_tta-tabs-container">
                            <ul className="vc_tta-tabs-list">
                                {children.map((child, i) => (
                                    <VisualComposerTabsOption
                                        {...child.props}
                                        key={child.props.tab_id}
                                        id={child.props.tab_id}
                                        title={child.props.title}
                                        active={
                                            activeTab === child.props.tab_id
                                        }
                                        activeHandler={setActiveTab}
                                    />
                                ))}
                            </ul>
                        </div>
                    ) : (
                        ''
                    )}
                </div>
            </div>
        </>
    );
};

export default VisualComposerTour;
