import React from 'react';

import VisualComposerSpacer from '../components/visual-composer/VisualComposerSpacer';

function VisualComposerSpacerShortCode(props) {
    const { el_class, height, css, el_id } = props;
    return (
        <VisualComposerSpacer
            el_class={el_class}
            el_id={el_id}
            height={height}
            css={css}
        />
    );
}

export default VisualComposerSpacerShortCode;
