import React from 'react';
import Head from 'next/head';

function VisualComposerTextBlock(originalProps) {
    let props = {
        ...originalProps
    };
    const buttonParametersExample = props.attr.link;
    const obj = {};
    const parameters = buttonParametersExample?.split('|') || [];

    for (let i = 0; i < parameters.length; i++) {
        const [key, value] = parameters[i].split(':');

        let decodedValue = decodeURIComponent(value);
        decodedValue.replace('');

        let replacedValue;
        replacedValue = decodedValue.replace(
            /^(https:\/\/terranovasecurity\.com|https:\/\/tndevelop\.wpengine\.com|https:\/\/tncstaging\.wpengine\.com)/gi,
            ''
        );
        replacedValue = replacedValue.replace(
            /^\/wp-content\//gi,
            `${process.env.NEXT_PUBLIC_WP_CONTENT_BASE}`
        );
        obj[key] = replacedValue;
    }
    let target = parameters[2] ? decodeURIComponent(parameters[2]) : '';
    target = target.search('_blank') ? (target = '_blank') : '';

    return (
        <>
            {props?.attr?.css ? (
                <Head>
                    <style type="text/css">{`
                    ${props?.attr?.css && props?.attr?.css}
                `}</style>
                </Head>
            ) : (
                ''
            )}

            <div
                id={props.attr.el_id ? props.attr.el_id : ''}
                className={`vc_btn3-container ${
                    props.attr.el_class ? props.attr.el_class : ''
                } ${
                    props.attr.css
                        ? props.attr.css?.split('{')[0].substring(1)
                        : ''
                } vc_btn3-${props.attr.align ? props.attr.align : 'inline'}`}
            >
                {props.attr.style === 'custom' && (
                    <a
                        target={target}
                        className={`vc_general  ${
                            props.attr.i_align || props.attr.add_icon === 'true'
                                ? `vc_btn3-icon-${props.attr.i_align || 'left'}`
                                : ''
                        } ${
                            props.attr.button_block ? 'vc_btn3-block' : ''
                        } vc_btn3 vc_btn3-size-${
                            props.attr.size ? props.attr.size : 'md'
                        } vc_btn3-shape-${
                            props.attr.shape ? props.attr.shape : 'rounded'
                        } vc_btn3-style-${
                            props.attr.style ? props.attr.style : 'modern'
                        }`}
                        href={obj.url}
                        title={obj.title}
                        style={
                            props.attr.custom_background
                                ? {
                                      backgroundColor:
                                          props.attr.custom_background,
                                      color: props.attr.custom_text
                                  }
                                : {}
                        }
                    >
                        {props.attr.add_icon === 'true' &&
                        props.attr.i_align !== 'right' &&
                        !props.attr.i_align ? (
                            <i
                                className={`vc_btn3-icon ${
                                    props.attr.i_icon_fontawesome ||
                                    props.attr.i_icon_openiconic ||
                                    props.attr.i_icon_typicons ||
                                    props.attr.i_icon_entypo ||
                                    props.attr.i_icon_linecons ||
                                    props.attr.i_icon_monosocial ||
                                    props.attr.i_icon_material ||
                                    props.attr.i_icon_pixelicons
                                }`}
                            ></i>
                        ) : (
                            ''
                        )}
                        <text
                            dangerouslySetInnerHTML={{
                                __html: props.attr.title
                            }}
                        />
                        {props.attr.add_icon === 'true' &&
                        props.attr.i_align === 'right' ? (
                            <i
                                className={`vc_btn3-icon ${
                                    props.attr.i_icon_fontawesome ||
                                    props.attr.i_icon_openiconic ||
                                    props.attr.i_icon_typicons ||
                                    props.attr.i_icon_entypo ||
                                    props.attr.i_icon_linecons ||
                                    props.attr.i_icon_monosocial ||
                                    props.attr.i_icon_material ||
                                    props.attr.i_icon_pixelicons
                                }`}
                            ></i>
                        ) : (
                            ''
                        )}
                    </a>
                )}
                {props.attr.style === '3d' ||
                props.attr.style === 'modern' ||
                props.attr.style === 'classic' ||
                props.attr.style === 'flat' ||
                props.attr.style === 'outline' ? (
                    <a
                        target={target}
                        className={`vc_general ${
                            props.attr.i_align || props.attr.add_icon === 'true'
                                ? `vc_btn3-icon-${props.attr.i_align || 'left'}`
                                : ''
                        } ${
                            props.attr.button_block ? 'vc_btn3-block' : ''
                        } vc_btn3 vc_btn3-color-${
                            props.attr.color ? props.attr.color : 'gray'
                        } vc_btn3-size-${
                            props.attr.size ? props.attr.size : 'md'
                        } vc_btn3-shape-${
                            props.attr.shape ? props.attr.shape : 'rounded'
                        } vc_btn3-style-${
                            props.attr.style ? props.attr.style : 'modern'
                        }`}
                        href={obj.url}
                        title={obj.title}
                        style={
                            props.attr.custom_background
                                ? {
                                      backgroundColor:
                                          props.attr.custom_background,
                                      color: props.attr.custom_text
                                  }
                                : {}
                        }
                    >
                        {props.attr.add_icon === 'true' &&
                        props.attr.i_align !== 'right' ? (
                            <i
                                className={`vc_btn3-icon ${
                                    props.attr.i_icon_fontawesome ||
                                    props.attr.i_icon_openiconic ||
                                    props.attr.i_icon_typicons ||
                                    props.attr.i_icon_entypo ||
                                    props.attr.i_icon_linecons ||
                                    props.attr.i_icon_monosocial ||
                                    props.attr.i_icon_material ||
                                    props.attr.i_icon_pixelicons
                                }`}
                            ></i>
                        ) : (
                            ''
                        )}
                        <text
                            dangerouslySetInnerHTML={{
                                __html: props.attr.title
                            }}
                        />
                        {props.attr.add_icon === 'true' &&
                        props.attr.i_align === 'right' ? (
                            <i
                                className={`vc_btn3-icon ${
                                    props.attr.i_icon_fontawesome ||
                                    props.attr.i_icon_openiconic ||
                                    props.attr.i_icon_typicons ||
                                    props.attr.i_icon_entypo ||
                                    props.attr.i_icon_linecons ||
                                    props.attr.i_icon_monosocial ||
                                    props.attr.i_icon_material ||
                                    props.attr.i_icon_pixelicons
                                }`}
                            ></i>
                        ) : (
                            ''
                        )}
                    </a>
                ) : (
                    ''
                )}
                {props.attr.style === 'outline-custom' && (
                    <a
                        target={target}
                        onMouseEnter={(e) => {
                            (e.target.style.backgroundColor = props.attr
                                .outline_custom_hover_background
                                ? props.attr.outline_custom_hover_background
                                : ''),
                                (e.target.style.borderColor = props.attr
                                    .outline_custom_hover_background
                                    ? props.attr.outline_custom_hover_background
                                    : ''),
                                (e.target.style.color = props.attr
                                    .outline_custom_hover_text
                                    ? props.attr.outline_custom_hover_text
                                    : '');
                        }}
                        onMouseLeave={(e) => {
                            (e.target.style.backgroundColor = ''),
                                (e.target.style.borderColor =
                                    props.attr.outline_custom_color),
                                (e.target.style.color =
                                    props.attr.outline_custom_color);
                        }}
                        className={`vc_general ${
                            props.attr.i_align || props.attr.add_icon === 'true'
                                ? `vc_btn3-icon-${props.attr.i_align || 'left'}`
                                : ''
                        } ${
                            props.attr.button_block ? 'vc_btn3-block' : ''
                        } vc_btn3 vc_btn3-size-${
                            props.attr.size ? props.attr.size : 'md'
                        } vc_btn3-shape-${
                            props.attr.shape ? props.attr.shape : 'rounded'
                        } vc_btn3-style-${
                            props.attr.style ? props.attr.style : 'modern'
                        } ${
                            props.attr.style != 'outline-custom' ||
                            props.attr.style != 'custom'
                                ? ''
                                : 'vc_btn3-color-grey'
                        }`}
                        href={obj.url}
                        title={obj.title}
                        style={
                            props.attr.outline_custom_color
                                ? {
                                      borderColor:
                                          props.attr.outline_custom_color,
                                      color: props.attr.outline_custom_color
                                  }
                                : {}
                        }
                    >
                        {props.attr.add_icon &&
                        props.attr.i_align !== 'right' ? (
                            <i
                                className={`vc_btn3-icon ${
                                    props.attr.i_icon_fontawesome ||
                                    props.attr.i_icon_openiconic ||
                                    props.attr.i_icon_typicons ||
                                    props.attr.i_icon_entypo ||
                                    props.attr.i_icon_linecons ||
                                    props.attr.i_icon_monosocial ||
                                    props.attr.i_icon_material ||
                                    props.attr.i_icon_pixelicons
                                }`}
                            ></i>
                        ) : (
                            ''
                        )}
                        <text
                            dangerouslySetInnerHTML={{
                                __html: props.attr.title
                            }}
                        />
                        {props.attr.add_icon &&
                        props.attr.i_align === 'right' ? (
                            <i
                                className={`vc_btn3-icon ${
                                    props.attr.i_icon_fontawesome ||
                                    props.attr.i_icon_openiconic ||
                                    props.attr.i_icon_typicons ||
                                    props.attr.i_icon_entypo ||
                                    props.attr.i_icon_linecons ||
                                    props.attr.i_icon_monosocial ||
                                    props.attr.i_icon_material ||
                                    props.attr.i_icon_pixelicons
                                }`}
                            ></i>
                        ) : (
                            ''
                        )}
                    </a>
                )}
                {props.attr.style === 'gradient-custom' ||
                props.attr.style === 'gradient' ? (
                    <a
                        target={target}
                        className={`vc_general ${
                            props.attr.button_block ? 'vc_btn3-block' : ''
                        } ${
                            props.attr.i_align || props.attr.add_icon === 'true'
                                ? `vc_btn3-icon-${props.attr.i_align || 'left'}`
                                : ''
                        } vc_btn3 vc_btn3-size-${
                            props.attr.size ? props.attr.size : 'md'
                        } vc_btn3-shape-${
                            props.attr.shape ? props.attr.shape : 'rounded'
                        } vc_btn3-style-${
                            props.attr.style ? props.attr.style : 'modern'
                        } ${
                            props.attr.style != 'outline-custom' ||
                            props.attr.style != 'custom'
                                ? ''
                                : 'vc_btn3-color-grey'
                        }`}
                        href={obj.url}
                        title={obj.title}
                        style={
                            props.attr.gradient_custom_color_1 ||
                            props.attr.gradient_color_1
                                ? {
                                      border: 'none',
                                      backgroundColor: props.attr
                                          .gradient_custom_color_1
                                          ? props.attr.gradient_custom_color_1
                                          : props.attr.gradient_color_1,
                                      color: props.attr.gradient_text_color
                                          ? props.attr.gradient_text_color
                                          : '#000',
                                      backgroundSize: '200% 100%',
                                      backgroundImage: `linear-gradient(to right, ${
                                          props.attr.gradient_custom_color_1
                                              ? props.attr
                                                    .gradient_custom_color_1
                                              : props.attr.gradient_color_1
                                      } 0%, ${
                                          props.attr.gradient_custom_color_2
                                              ? props.attr
                                                    .gradient_custom_color_2
                                              : props.attr.gradient_color_2
                                      } 50%,${
                                          props.attr.gradient_custom_color_1
                                              ? props.attr
                                                    .gradient_custom_color_1
                                              : props.attr.gradient_color_1
                                      } 100%)`
                                  }
                                : {}
                        }
                    >
                        {' '}
                        {props.attr.add_icon &&
                        props.attr.i_align !== 'right' ? (
                            <i
                                className={`vc_btn3-icon ${
                                    props.attr.i_icon_fontawesome ||
                                    props.attr.i_icon_openiconic ||
                                    props.attr.i_icon_typicons ||
                                    props.attr.i_icon_entypo ||
                                    props.attr.i_icon_linecons ||
                                    props.attr.i_icon_monosocial ||
                                    props.attr.i_icon_material ||
                                    props.attr.i_icon_pixelicons
                                }`}
                            ></i>
                        ) : (
                            ''
                        )}
                        <text
                            dangerouslySetInnerHTML={{
                                __html: props.attr.title
                            }}
                        />
                        {props.attr.add_icon &&
                        props.attr.i_align === 'right' ? (
                            <i
                                className={`vc_btn3-icon ${
                                    props.attr.i_icon_fontawesome ||
                                    props.attr.i_icon_openiconic ||
                                    props.attr.i_icon_typicons ||
                                    props.attr.i_icon_entypo ||
                                    props.attr.i_icon_linecons ||
                                    props.attr.i_icon_monosocial ||
                                    props.attr.i_icon_material ||
                                    props.attr.i_icon_pixelicons
                                }`}
                            ></i>
                        ) : (
                            ''
                        )}
                    </a>
                ) : (
                    ''
                )}
            </div>
            {/* <div
                id={props.attr.el_id && props.attr.el_id}
                className={`wpb_text_column wpb_content_element ${
                    props.attr.el_class ? props.attr.el_class : ''
                } ${
                    props.attr.css
                        ? props.attr.css.split('{')[0].substring(1)
                        : ''
                }`}
            >
                <div className="wpb_wrapper">
                    <div
                        dangerouslySetInnerHTML={{
                            __html: props.attr.content
                        }}
                    ></div>
                </div>
            </div> */}
        </>
    );
}

export default VisualComposerTextBlock;
