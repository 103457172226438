// TODO: Do we need to check the locale code and render a different text? We need to check this in the current backend app.

import React from 'react';

import { ARCHIVE_LAYOUTS } from '../../util/i18n';

const BlogHeading = ({ viewType, language, title, ...props }) => {
    return (
        <div className="container cdb-page-heading">
            <div className="row">
                <div className="col-sm-12">
                    <h1 className="cdb-page-title">
                    {ARCHIVE_LAYOUTS['blog_heading'][language]}
                    </h1>
                    {viewType === 'blog' ? (
                        <div className="cdb-page-description">
                            <p>
                            {ARCHIVE_LAYOUTS['blog_subheading'][language]}
                            </p>
                        </div>
                    ) : (
                        ''
                    )}
                </div>
            </div>
        </div>
    );
};

export default BlogHeading;
