import React from 'react';
import Head from 'next/head';

const HEADING_TAG_NAME_TO_JSX = {
    h2: ({ children }) => <h2>{children}</h2>,
    h3: ({ children }) => <h3>{children}</h3>,
    h4: ({ children }) => <h4>{children}</h4>,
    h5: ({ children }) => <h5>{children}</h5>,
    h6: ({ children }) => <h6>{children}</h6>
};

function CBIconBox(originalProps) {
    let props = {
        ...originalProps
    };
    props = props?.attr;

    const icon = props?.meta_properties?.url
        ? {
              id: props?.meta_properties.id,
              mediaItemId: props?.meta_properties.id,
              mediaItemUrl: props?.meta_properties.url,
              title: props?.meta_properties.title,
              sizes: null,
              srcSet: null,
              caption: props?.meta_properties.caption
          }
        : {};

    const HeadingComponent =
        HEADING_TAG_NAME_TO_JSX[props?.title_tag] ||
        (({ children }) => <h4>{children}</h4>);
    return (
        <>
            {props?.css ? (
                <Head>
                    <style type="text/css">{`
                    ${props?.css && props?.css}
                `}</style>
                </Head>
            ) : (
                ''
            )}
            <div
                className={`cdb-icon-box ${
                    props?.icon_type ? `cdb-style-${props?.icon_type}` : ''
                } ${props?.icon_size} cdb-icon-${
                    props?.icon_position
                } clearfix ${
                    props?.css ? props?.css.split('{')[0].substring(1) : ''
                } ${props?.el_class ? props?.el_class : ''}`}
            >
                <div className="cdb-icon-wrap">
                    {props?.icon_style === 'image' ? (
                        <div className="cdb-custom-icon-image">
                            <img
                                style={{
                                    fontSize: props?.custom_icon_size
                                        ? props?.custom_icon_size
                                        : ''
                                }}
                                src={icon.mediaItemUrl}
                                alt={icon.title ? icon.title : ''}
                            />
                        </div>
                    ) : (
                        <div className="cdb-icon-element">
                            <div
                                className="cdb-icon-element-inner"
                                style={{
                                    width: props?.shape_size
                                        ? props?.shape_size
                                        : '',
                                    margin: props?.icon_margin
                                        ? props?.icon_margin
                                        : '',
                                    backgroundColor: props?.icon_background_color
                                        ? props?.icon_background_color
                                        : ''
                                }}
                            >
                                <i
                                    style={{
                                        fontSize: props?.custom_icon_size
                                            ? props?.custom_icon_size
                                            : '',
                                        color: props?.icon_color
                                            ? props?.icon_color
                                            : ''
                                    }}
                                    className={`cdb-icon-item ${props?.icon}`}
                                ></i>
                            </div>
                        </div>
                    )}
                </div>
                <div
                    className="cdb-feature-content"
                    style={{
                        paddingLeft: props?.text_left_padding
                            ? props?.text_left_padding
                            : '',
                        paddingRight: props?.text_right_padding
                            ? props?.text_right_padding
                            : ''
                    }}
                >
                    {props?.title && (
                        <div className="cdb-feature-content-title">
                            <HeadingComponent>
                                <span
                                    style={{
                                        color: props?.title_color
                                            ? props?.title_color
                                            : '',
                                        fontWeight: props?.title_text_font_weight
                                            ? props?.title_text_font_weight
                                            : '',
                                        textTransform: props?.title_text_transform
                                            ? props?.title_text_transform
                                            : ''
                                    }}
                                >
                                    {props?.title}
                                </span>
                            </HeadingComponent>
                        </div>
                    )}

                    {props?.text && (
                        <div className="cdb-feature-content-text">
                            <div
                                style={{
                                    color: props?.text_color
                                        ? props?.text_color
                                        : ''
                                }}
                                dangerouslySetInnerHTML={{
                                    __html: props?.text
                                }}
                            />
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}
export default CBIconBox;
