// TODO: Replace h3 tag content with incoming text with the correct language

import React from 'react';
import NextNavLink from './../NextNavLink';

import { ARCHIVE_LAYOUTS } from '../../util/i18n';

const BlogCategoriesList = ({ categories, language, ...props }) => {
    return (
        <div className="widget widget_categories categories-2">
            <h3 className="cdb-sidearea-title">
                {ARCHIVE_LAYOUTS['categories'][language]}
            </h3>

            <ul>
                {categories.filter(category => category.count).map((category) => (
                    <li
                        key={category.databaseId}
                        className={`cat-item cat-item-${category.databaseId}`}
                    >
                        <NextNavLink href={category.uri}>
                            <a>{category.name}</a>
                        </NextNavLink>
                        {` (${category.count})`}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default BlogCategoriesList;
