import React from 'react';

/* Shortcodes */
import {
    ExampleContainerShortcode,
    ExampleColumnsShortcode,
    ExampleColumnShortcode
} from '../shortcodes/example';

import VisualComposerRowShortcode from '../shortcodes/vc_row';
import VisualComposerRowInnerShortcode from '../shortcodes/vc_row_inner';
import VisualComposerSectionShortcode from '../shortcodes/vc_section';
import VisualComposerColumnShortcode from '../shortcodes/vc_column';
import VisualComposerColumnInnerShortcode from '../shortcodes/vc_column_inner';
import VisualComposerAccordionShortcode from '../shortcodes/vc_tta_accordion';
import VisualComposerColumnTextShortcode from '../shortcodes/vc_column_text';
import VisualComposerSingleImageShortcode from './../shortcodes/vc_single_image';
import VisualComposerHoverboxShortcode from './../shortcodes/vc_hoverbox';
import VisualComposerSeparatorShortcode from './../shortcodes/vc_separator';
import VisualComposerTextSeparatorShortcode from './../shortcodes/vc_text_separator';
import VisualComposerSeparatorZigZagShortcode from './../shortcodes/vc_separator_zigzag';
import VisualComposerMessageShortcode from './../shortcodes/vc_message';
import VisualComposerBtn from './../shortcodes/vc_btn';
import VisualComposerTabsShortcode from './../shortcodes/vc_tabs';
import VisualComposerTabsSectionShortcode from './../shortcodes/vc_tabs_section';
import VisualComposerTourShortcode from './../shortcodes/vc_tta_tour';
import VisualComposerCustomHeadingShortcode from './../shortcodes/vc_custom_heading';
import VisualComposerPostGridShortcode from './../shortcodes/vc_basic_grid';
import VisualComposerSpacerShortCode from './../shortcodes/vc_empty_space';
import VisualComposerIconShortCode from './../shortcodes/vc_icon';
import VisualComposerVideoShortCode from './../shortcodes/vc_video';
import VisualComposerImagesCarouselShortCode from './../shortcodes/vc_images_carousel';

import VisualComposerRawHTMLShortcode from '../shortcodes/vc_raw_html';

import CodebeansTestimonialsShortcode from './../shortcodes/codebean_testimonials';
import CodebeansTestimonialsItemShortcode from './../shortcodes/codebean_testimonials_item';
import CBSectionTitleShortcode from './../shortcodes/cb_section_title';
import CBSectionSubTitleShortcode from './../shortcodes/cb_section_subtitle';
import CBAnimatedBoxShortcode from './../shortcodes/cb_animated_box';
import CBIconBoxShortcode from './../shortcodes/cb_icon_box';
import CBTeamMemberShortcode from './../shortcodes/cb_team_member';
import CBAnimatedCounterShortcode from './../shortcodes/cb_animated_counter';
import CBPricingTableShortcode from './../shortcodes/cb_pricing_table';
import CBCountDownTimerShortcode from './../shortcodes/cb_countdown_timer';
import CBIconListShortcode from './../shortcodes/cb_icon_list';

import TSHomeSliderShortcode from './../shortcodes/ts_home_slider';

export const shortcodeToComponent = {
    sd_example_container: ExampleContainerShortcode,
    sd_example_columns: ExampleColumnsShortcode,
    sd_example_column: ExampleColumnShortcode,

    vc_row: VisualComposerRowShortcode,
    vc_row_inner: VisualComposerRowInnerShortcode,
    vc_column: VisualComposerColumnShortcode,
    vc_column_inner: VisualComposerColumnInnerShortcode,
    vc_section: VisualComposerSectionShortcode,
    vc_column_text: VisualComposerColumnTextShortcode,
    vc_single_image: VisualComposerSingleImageShortcode,
    vc_hoverbox: VisualComposerHoverboxShortcode,
    vc_separator: VisualComposerSeparatorShortcode,
    vc_text_separator: VisualComposerTextSeparatorShortcode,
    vc_zigzag: VisualComposerSeparatorZigZagShortcode,
    vc_message: VisualComposerMessageShortcode, //Alerts
    vc_tta_accordion: VisualComposerAccordionShortcode,
    vc_tta_tabs: VisualComposerTabsShortcode, //Basic Tabs
    vc_tta_tour: VisualComposerTourShortcode, //Lateral Tabs
    vc_tta_section: VisualComposerTabsSectionShortcode, //Basic Tabs child
    vc_custom_heading: VisualComposerCustomHeadingShortcode,
    vc_basic_grid: VisualComposerPostGridShortcode,
    vc_empty_space: VisualComposerSpacerShortCode,
    vc_icon: VisualComposerIconShortCode,
    vc_video: VisualComposerVideoShortCode,
    vc_images_carousel: VisualComposerImagesCarouselShortCode,

    vc_btn: VisualComposerBtn,
    codebean_section_title: CBSectionTitleShortcode,
    codebean_section_subtitle: CBSectionSubTitleShortcode,
    codebean_animated_box: CBAnimatedBoxShortcode,
    codebean_icon_box: CBIconBoxShortcode,
    codebean_team_member: CBTeamMemberShortcode,
    codebean_animated_counter: CBAnimatedCounterShortcode,
    codebean_countdown_timer: CBCountDownTimerShortcode,
    codebean_icon_list_item: CBIconListShortcode,

    vc_raw_html: VisualComposerRawHTMLShortcode,

    codebean_testimonials: CodebeansTestimonialsShortcode,
    codebean_testimonial_item: CodebeansTestimonialsItemShortcode,
    codebean_pricing_table: CBPricingTableShortcode, //Pricing Tables

    // --------------------------------
    // -------- SpliceDigital Shortcodes
    // --------------------------------

    ts_home_slider: TSHomeSliderShortcode,
    ts_slider_item: VisualComposerSectionShortcode
};

export function renderShortcodes(shortcodes, pageProps = {}) {   
    if (!shortcodes) {
        return null;
    }

    return shortcodes.map((shortcode, index) => {
        /*
         * If the shortcode content is not already a tree, then it is wrapped
         * in a tagless root, so immediately render its children instead.
         */
        if (!shortcode.name && shortcode.children.length) {
            return renderShortcodes(shortcode.children, pageProps);
        }

        const ShortcodeComponent = shortcodeToComponent[shortcode.name];

        if (!ShortcodeComponent) {
            /* Tried to render a block without a local equivalent */
            return null;
        }

        return (
            <ShortcodeComponent
                key={pageProps.id + '-' + shortcode.attributes.node_id}
                {...shortcode.attributes}
                content={shortcode.content}
                pageProps={pageProps}
            >
                {renderShortcodes(shortcode.children, pageProps)}
            </ShortcodeComponent>
        );
    });
}
