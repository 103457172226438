import React from 'react';
import BlogCategoriesList from '../Blog/BlogCategoriesList';
import BlogTagsList from '../Blog/BlogTagsList';
import BlogRecentPosts from '../Blog/BlogRecentPosts';
import BlogSearchWidget from '../Blog/BlogSearchWidget';

const BlogSidebar = ({ categories, recentPosts, tags, locale, ...props }) => {
    return (
        <div className="col-md-3">
            <aside className="sidebar" role="complementary">
                <div className="fixed-sidebar cdb-sidebar-inner">
                    <div className="widget widget_search search-2">
                        <h3 className="cdb-sidearea-title">Search</h3>{' '}
                        {/*Replace this with an actual incoming title*/}
                        <BlogSearchWidget locale={locale} />
                    </div>

                    <BlogCategoriesList categories={categories} />
                    <BlogRecentPosts recentPosts={recentPosts} />
                    <BlogTagsList tags={tags} />
                </div>
            </aside>
        </div>
    );
};

export default BlogSidebar;
