import React from 'react';

import VisualComposerCustomHeading from '../components/visual-composer/VisualComposerCustomHeading';

function VisualComposerCustomHeadingShortcode(props) {
    const {
        el_class,
        el_id,
        font_container,
        google_fonts,
        link,
        text,
        css,
        meta_properties
    } = props;

    return (
        <VisualComposerCustomHeading
            el_class={el_class}
            el_id={el_id}
            font_container={font_container}
            google_fonts={google_fonts}
            link={link}
            text={meta_properties['transformed_text'] || text}
            css={css}
        />
    );
}

export default VisualComposerCustomHeadingShortcode;
