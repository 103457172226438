import { list } from 'postcss';
import React from 'react';
import NextNavLink from '../NextNavLink';

const FooterNavMenu = ({ title, items, ...props }) => {
    return (
        <div className={`widget widget_nav_menu`}>
            <h4 className="cdb-widget-title">{title.toUpperCase()}</h4>
            <div className="menu-footer-training-container">
                <ul className="menu">
                    {items.map((item, index) => (
                        <li
                            key={index}
                            id={`menu-item-${item.key}`}
                            className={`menu-item menu-item-type-post_type menu-item-object-page menu-item-${item.key}`}
                        >
                            <NextNavLink href={item.path || item.url}>
                                <a>{item.title}</a>
                            </NextNavLink>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default FooterNavMenu;
