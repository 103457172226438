import React from 'react';

const SubFooter = ({ lang, options }) => {
    return (
        <div className="cdb-subfooter" style={{ backgroundColor: '#3c3c3c' }}>
            <div className="container">
                <div className="row">
                    <div className="cdb-subfooter-inside">
                        <div className="col-sm-5 cdb-subfooter-left">
                            <div className="terranova-bottom-bar-left">
                                <div
                                    className="textwidget"
                                    dangerouslySetInnerHTML={{
                                        __html:
                                            lang === 'en_US'
                                                ? options.footer.copyright
                                                : options.footer.copyrightFr
                                    }}
                                ></div>
                            </div>
                        </div>
                        <div className="col-sm-2 cdb-subfooter-center">
                            <a
                                href="#"
                                target="_self"
                                className="cdb-go-top"
                                aria-label="go to top"
                                onClick={() => {
                                    document.body.scrollTop = 0; // For Safari
                                    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
                                }}
                            >
                                <i className="fas fa-angle-up"></i>
                            </a>
                        </div>
                        <div className="col-sm-5 cdb-subfooter-right">
                            <div className="terranova-bottom-bar-right">
                                <div
                                    className="textwidget"
                                    style={{ padding: `13px 0` }}
                                >
                                    <p>
                                        {options.footer.logo.sourceUrl ? (
                                            <img
                                                src={
                                                    options.footer.logo
                                                        .sourceUrl
                                                }
                                                alt="Terranova Security"
                                                width="40%"
                                            />
                                        ) : (
                                            ''
                                        )}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SubFooter;
