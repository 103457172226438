/*
 * With current_locale_code being the *current* locale and other_locale_code
 * being the other, this map retrieves the readable name of that locale:
 *
 * LOCALE_NAME_FROM_LOCALE_CODE[current_locale_code][other_locale_code]
 */
export const LOCALE_NAME_FROM_LOCALE_CODE = {
    en_US: {
        en_US: 'English',
        fr_CA: 'French (CA)',
        fr_FR: 'French (FR)'
    },
    fr_CA: {
        en_US: 'Anglais',
        fr_CA: 'Français (CA)',
        fr_FR: 'Français (FR)'
    },
    fr_FR: {
        en_US: 'Anglais',
        fr_CA: 'Français (CA)',
        fr_FR: 'Français (FR)'
    }
};

export const MENU_NAMES = {
    en: {
        header: 'Header',
        'footer-training': 'Training',
        'footer-platform': 'Platform',
        'footer-free-tools': 'Free Tools',
        'footer-company': 'Company',
        'social': 'Stay connected',
        'contact-us': 'Get in touch'
    },
    fr: {
        header: 'Header',
        'footer-training': 'Formation',
        'footer-platform': 'Plateforme',
        'footer-free-tools': 'Outils gratuits',
        'footer-company': 'Entreprise',
        'social': 'Suivez-nous',
        'contact-us': 'Contactez-nous'
    },
    'fr-fr': {
        header: 'Header',
        'footer-training': 'Formation',
        'footer-platform': 'Plateforme',
        'footer-free-tools': 'Outils gratuits',
        'footer-company': 'Entreprise',
        'social': 'Suivez-nous',
        'contact-us': 'Contactez-nous'
    }
};

export const LOCALE_TO_HREFLANG = {
    'en_US': 'en',
    'fr_CA': 'fr-ca',
    'fr_FR': 'fr-fr'
};

export const LOCALE_TO_CANONICAL_PREFIX = {
    'en_US': '/',
    'fr_CA': '/fr/',
    'fr_FR': '/fr-fr/'
};

export const TRANSLATION_LINK_OVERRIDES = {
    '/fr-fr/home-fr-fr/': '/fr-fr/',
    '/fr/home-2/': '/fr/'
};

export const TRANSLATION_MENU_KEY_NAME = {
    en: {
        header: 'header',
        'footer-training': 'footer-training',
        'footer-platform': 'footer-platform',
        'footer-free-tools': 'footer-free-tools',
        'footer-company': 'footer-company'
    },
    fr: {
        header: 'headerfr',
        'footer-training': 'footer-training-fr',
        'footer-platform': 'footer-platform-fr',
        'footer-free-tools': 'footer-free-tools-fr',
        'footer-company': 'footer-company-fr'
    },
    'fr-fr': {
        header: 'headerfrfr',
        'footer-training': 'footer-training-frfr',
        'footer-platform': 'footer-platform-frfr',
        'footer-free-tools': 'footer-free-tools-frfr',
        'footer-company': 'footer-company-frfr'
    }
};

export function getTranslationsFromArchiveUri(uri) {
    if(uri.startsWith('/blog') 
    || uri.startsWith('/category')
    || uri.startsWith('/tag')) {
        return [
            {
                "locale": "fr_CA",
                "href": "https://terranova.splice.website/fr/blogue",
                "id": "1"
            },
            {
                "locale": "fr_FR",
                "href": "https://terranova.splice.website/fr/blogue",
                "id": "1"
            }
        ]
    } else {
        return [
            {
                "locale": "en_US",
                "href": "https://terranova.splice.website/blog",
                "id": "1"
            }
        ]
    }

    return [
    ];
};

export const ARCHIVE_LAYOUTS = {
    'blog_heading': {
        'en': 'Cyber Security Awareness Blog',
        'fr': 'Blogue de sensibilisation à la cybersécurité',
        'fr-fr': 'Blogue de sensibilisation à la cybersécurité'
    },
    'blog_subheading': {
        'en': 'Insights on how to change behavior and reduce the human risk',
        'fr': 'Des nouvelles idées pour changer les comportements et réduire le risque humain.',
        'fr-fr': 'Des nouvelles idées pour changer les comportements et réduire le risque humain.'
    },
    'continue_reading': {
        'en': 'Continue Reading',
        'fr': 'Lire Plus',
        'fr-fr': 'Lire Plus'
    },
    'search': {
        'en': 'Search',
        'fr': 'Rechercher',
        'fr-fr': 'Rechercher'
    },
    'categories': {
        'en': 'Categories',
        'fr': 'Catégories',
        'fr-fr': 'Catégories'
    },
    'recent': {
        'en': 'Recent Posts',
        'fr': 'Articles récents',
        'fr-fr': 'Articles récents'
    },
    'tags': {
        'en': 'Tags',
        'fr': 'Étiquettes',
        'fr-fr': 'Étiquettes'
    },
    'loading': {
        'en': 'Loading...',
        'fr': 'Chargement...',
        'fr-fr': 'Chargement...'
    },
    'search_results': {
        'en': 'results for',
        'fr': 'résultats pour',
        'fr-fr': 'résultats pour'
    },
    'search_no_results': {
        'en': 'No results found.',
        'fr': 'Aucun résultat trouvé.',
        'fr-fr': 'Aucun résultat trouvé.',
    }
};



export const TOP_NAV = {
    'blog': {
        'en': 'Blog',
        'fr': 'Blogue',
        'fr-fr': 'Blogue'
    },
    'blog_link': {
        'en': '/blog',
        'fr': '/fr/blogue',
        'fr-fr': '/fr/blogue'
    },
    'partner': {
        'en': 'Partner',
        'fr': 'Partenaire',
        'fr-fr': 'Partenaire'
    },
    'partner_link': {
        'en': '/partner',
        'fr': '/fr/partenaires/',
        'fr-fr': '/fr-fr/partenaires/',
    },
    'request_demo': {
        'en': 'Request a demo',
        'fr': 'Demandez une démo',
        'fr-fr': 'Demandez une démo'
    },
    'request_demo_link': {
        'en': '/request-demo',
        'fr': '/fr/demander-demo',
        'fr-fr': '/fr/demander-demo'
    }
};

export const VALID_LANGUAGE_CODES = ['en', 'fr', 'fr-fr'];

export const LANGUAGE_CODE_FROM_LOCALE_CODE = {
    'en_US': 'en',
    'fr_CA': 'fr',
    'fr_FR': 'fr-fr'
};

export const LOCALE_CODE_FROM_LANGUAGE_CODE = {
    'en': 'en_US',
    'fr': 'fr_CA',
    'fr-fr': 'fr_FR'
};

export const ISO_LANGUAGE_CODE_FROM_LANGUAGE_CODE = {
    'en': 'en-US',
    'fr': 'fr-CA',
    'fr-fr': 'fr-FR'
};

export const LOCALE_NAME_FROM_LANGUAGE_CODE = {
    en: {
        'en': 'English',
        'fr': 'French (CA)',
        'fr-fr': 'French (FR)'
    },
    fr: {
        'en': 'Anglais',
        'fr': 'Français (CA)',
        'fr-fr': 'Français (FR)'
    },
    'fr-fr': {
        'en': 'Anglais',
        'fr': 'Français (CA)',
        'fr-fr': 'Français (FR)'
    }
};
