import React, { useEffect, useState } from 'react';
import Router from 'next/router';

import SwiperCore, {
    Autoplay,
    Navigation,
    Pagination,
    Keyboard,
    Mousewheel,
    EffectFade,
    EffectFlip
} from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';

import { useRouteChange } from '../../util/hooks';

import 'swiper/swiper-bundle.css';
import 'swiper/components/effect-cube/effect-cube.min.css';
import 'swiper/components/effect-fade/effect-fade.min.css';
import 'swiper/components/effect-flip/effect-flip.min.css';

class TSHomeSlider extends React.Component {
    handleRouteChange() {
        if(this.swiper && !this.swiper.destroyed) {
            setTimeout(() => this.swiper.update());
        }
    }

    componentDidMount() {
        Router.events.on('routeChangeComplete', this.handleRouteChange.bind(this));
    }

    render() {
        SwiperCore.use([
            Autoplay,
            Keyboard,
            Mousewheel,
            Navigation,
            Pagination,
            EffectFade,
            EffectFlip
        ]);

        const {
            id = '',
            effect = '',
            arrows = false,
            dots = false,
            autoplay = false,
            autoplayDelay = 5000,
            loop = false,
            keyboard = false,
            mousewheel = false,
            children
        } = this.props;

        return (
            <>
                <Swiper
                    onInit={(swiper) => {
                        this.swiper = swiper;
                    }}
                    autoHeight={true}
                    className=""
                    id={`main`}
                    autoplay={autoplay ? {
                        delay: autoplayDelay ? parseInt(autoplayDelay) : 5000
                    } : false}
                    loop={loop && loop === "true"}
                    keyboard={eval(keyboard)}
                    mousewheel={eval(mousewheel)}
                    slidesPerView={1}
                    effect={effect}
                    navigation={eval(arrows)}
                    pagination={eval(dots)}
                >
                    {children.map((child, i) => (
                        <SwiperSlide key={`swiper-slide-${i}`}>
                            <div>{child}</div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </>
        );
    }
};

export default TSHomeSlider;
