import React from 'react';

import CBSectionSubTitle from '../components/visual-composer/CBSectionSubTitle';

function CBSectionSubTitleShortcode(props) {
    const { color, text, text_align, width, el_class } = props;
    return (
        <CBSectionSubTitle
            color={color}
            text={text}
            text_align={text_align}
            width={width}
            el_class={el_class}
        />
    );
}

export default CBSectionSubTitleShortcode;
