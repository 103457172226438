import React from 'react';
import Head from 'next/head';
const HEADING_TAG_NAME_TO_JSX = {
    h1: ({ children, style }) => <h1 style={style}>{children}</h1>,
    h2: ({ children, style }) => <h2 style={style}>{children}</h2>,
    h3: ({ children, style }) => <h3 style={style}>{children}</h3>,
    h4: ({ children, style }) => <h4 style={style}>{children}</h4>,
    h5: ({ children, style }) => <h5 style={style}>{children}</h5>,
    h6: ({ children, style }) => <h6 style={style}>{children}</h6>,
    p: ({ children, style }) => (
        <div className="custom-heading-p-tag" style={style}>
            {children}
        </div>
    ),
    div: ({ children, style }) => <div style={style}>{children}</div>
};
function VisualComposerCustomHeading({
    el_class,
    el_id,
    font_container,
    google_fonts,
    link,
    text,
    css
}) {
    const parameters = font_container.split('|');
    let obj = {};
    for (let i = 0; i < parameters.length; i++) {
        const [key, value] = parameters[i].split(':');
        obj[key] = decodeURIComponent(value);
    }

    let googleFont = decodeURIComponent(google_fonts).split(':')[1];
    let fontStyle = decodeURIComponent(google_fonts).split(':')[5];
    let googleFontStyle = decodeURIComponent(google_fonts).split(':')[3];

    googleFontStyle = String(googleFontStyle).split(' ');

    const HeadingComponent =
        HEADING_TAG_NAME_TO_JSX[obj.tag] ||
        (({ text }) => (
            <div
                dangerouslySetInnerHTML={{
                    __html: text
                }}
            />
        ));
    return (
        <>
            {css ? (
                <Head>
                    <style type="text/css">{`
                    ${css && css}
                `}</style>
                </Head>
            ) : (
                ''
            )}
            <div
                className={`vc_custom_heading ${
                    css ? css.split('{')[0].substring(1) : ''
                } ${el_class ? el_class : ''}`}
                id={el_id ? el_id : ''}
            >
                <HeadingComponent
                    style={{
                        fontSize: obj?.font_size ? `${obj.font_size}px` : '',
                        textAlign: obj?.text_align ? obj.text_align : '',
                        color: obj?.color ? obj.color : '',
                        line_height: obj?.line_height ? obj.line_height : '',
                        fontFamily: googleFont ? googleFont : 'inherit',
                        fontWeight: googleFontStyle[0]
                            ? googleFontStyle[0]
                            : 'inherit',
                        fontStyle:
                            fontStyle ||
                            googleFontStyle[2] ||
                            googleFontStyle[1]
                                ? fontStyle ||
                                  googleFontStyle[2] ||
                                  googleFontStyle[1]
                                : 'inherit'
                    }}
                >
                    <span
                        dangerouslySetInnerHTML={{
                            __html: text
                        }}
                    />
                </HeadingComponent>
            </div>
        </>
    );
}

export default VisualComposerCustomHeading;
