import React, { useState } from 'react';

import { ARCHIVE_LAYOUTS } from '../../util/i18n';

const BlogSearchWidget = ({ locale, ...props }) => {
    // -------------
    // States
    // -------------
    const [searchFieldValue, setSearchFieldValue] = useState('');

    // -------------
    // Handlers
    // -------------

    let language; // Depending of what locale code is coming from the pageProps (propsDrilling T_T) it fires the search handler with a different language code.

    if (locale === 'en_US') {
        language = 'en';
    } else if (locale === 'fr_CA') {
        language = 'fr';
    } else if (locale === 'fr_FR') {
        language = 'fr-fr';
    } else {
        language = 'en';
    }

    const fireSearch = (term) => {
        window.location.href =
            language !== 'en'
            ? `/${language}/search/?q=${term}&lang=${language}`
            : `/search/?q=${term}&lang=${language}`;
    };

    const onSubmitHandler = (e) => {
        e.preventDefault();
        fireSearch(searchFieldValue);
    };

    return (
        <form
            className="search-form"
            role="search"
            onSubmit={(e) => onSubmitHandler(e)}
        >
            <input
                className="search-input form-control"
                type="search"
                name="s"
                defaultValue={searchFieldValue}
                onChange={(e) => {
                    setSearchFieldValue(e.currentTarget.value);
                }}
                placeholder={`${ARCHIVE_LAYOUTS['search'][language]}…`}
            />
            <button
                className="search-submit"
                aria-label="search"
                role="none"
                type="submit"
                role="button"
            >
                <i className="fas fa-search"></i>
            </button>
        </form>
    );
};

export default BlogSearchWidget;
