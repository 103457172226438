import React, { useEffect, useState } from 'react';

import { Transition } from '@headlessui/react';
import ExampleMenu from '../ExampleMenu';
import NextNavLink from '../NextNavLink';

import { makeLinkRelative } from '../../util/wordpress';
import {
    getTranslationsFromArchiveUri,
    LOCALE_NAME_FROM_LOCALE_CODE,
    TOP_NAV,
    TRANSLATION_LINK_OVERRIDES,
    TRANSLATION_MENU_KEY_NAME
} from '../../util/i18n.js';
const isServer = typeof window === 'undefined';
const WOW = !isServer ? require('wow.js') : null;

import BlogSearchWidget from '../Blog/BlogSearchWidget';
function ExampleTranslationPicker({
    locale: { locale },
    language,
    translations,
    options,
    template
}) {
    let facebook = options?.socialLinks?.facebook;
    let linkedin = options?.socialLinks?.linkedin;
    let twitter = options?.socialLinks?.twitter;
    let youtube = options?.socialLinks?.youtube;
    let phoneNumber =
        language === 'fr-fr'
            ? '33 7 61 69 79 79'
            : options?.topNavigation?.phoneNumber;
    const [searchOpen, setSearchOpen] = useState(false);
    return (
        <>
            {template !== 'Landing Page' ? (
                <div className="cdb-top-header">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-3 cdb-top-header-left"></div>
                            <div className="col-sm-9 cdb-top-header-right">
                                {options?.topNavigation?.phoneNumber && (
                                    <div className="widget widget_text cdb-top-bar-widget">
                                        <div className="cdb-top-bar-widget-inner">
                                            <div className="textwidget">
                                                <div className="cdb-icon-list-item">
                                                    <div className="cdb-list-icon-wrap">
                                                        <i
                                                            className="ti-mobile"
                                                            style={{
                                                                color:
                                                                    '#000000',
                                                                fontSize: '15px'
                                                            }}
                                                        ></i>
                                                    </div>
                                                    <div className="cdb-list-icon-content">
                                                        <p>
                                                            <span
                                                                className="cdb-list-icon-title"
                                                                style={{
                                                                    color:
                                                                        '#000000',
                                                                    fontSize:
                                                                        '12px'
                                                                }}
                                                            >
                                                                {phoneNumber}
                                                            </span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <div className="widget widget_text cdb-top-bar-widget">
                                    <div className="cdb-top-bar-widget-inner">
                                        <div className="textwidget">
                                            <p>|</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="widget widget_text cdb-top-bar-widget">
                                    <div className="cdb-top-bar-widget-inner">
                                        <div className="textwidget">
                                            <p>
                                                <NextNavLink
                                                    href={
                                                        TOP_NAV['blog_link'][
                                                            language
                                                        ]
                                                    }
                                                >
                                                    <a
                                                        aria-label={
                                                            TOP_NAV['blog'][
                                                                language
                                                            ]
                                                        }
                                                    >
                                                        {
                                                            TOP_NAV['blog'][
                                                                language
                                                            ]
                                                        }
                                                    </a>
                                                </NextNavLink>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="widget widget_text cdb-top-bar-widget">
                                    <div className="cdb-top-bar-widget-inner">
                                        <div className="textwidget">
                                            <p>|</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="widget widget_text cdb-top-bar-widget">
                                    <div className="cdb-top-bar-widget-inner">
                                        <div className="textwidget">
                                            <p>
                                                <NextNavLink
                                                    href={
                                                        TOP_NAV['partner_link'][
                                                            language
                                                        ]
                                                    }
                                                >
                                                    <a
                                                        aria-label={
                                                            TOP_NAV['partner'][
                                                                language
                                                            ]
                                                        }
                                                    >
                                                        {
                                                            TOP_NAV['partner'][
                                                                language
                                                            ]
                                                        }
                                                    </a>
                                                </NextNavLink>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="widget widget_text cdb-top-bar-widget">
                                    <div className="cdb-top-bar-widget-inner">
                                        <div className="textwidget">
                                            <ul className="cdb-social-links">
                                                {facebook && (
                                                    <li>
                                                        <a
                                                            aria-label="Facebook"
                                                            href={facebook}
                                                            target="_blank"
                                                            className="Facebook"
                                                            title="Facebook"
                                                            rel="noopener noreferrer"
                                                        >
                                                            <i className="fab fa-facebook-f"></i>
                                                        </a>
                                                    </li>
                                                )}
                                                {linkedin && (
                                                    <li>
                                                        <a
                                                            aria-label="LinkedIn"
                                                            href={linkedin}
                                                            target="_blank"
                                                            className="LinkedIn"
                                                            title="LinkedIn"
                                                            rel="noopener noreferrer"
                                                        >
                                                            <i className="fab fa-linkedin-in"></i>
                                                        </a>
                                                    </li>
                                                )}
                                                {twitter && (
                                                    <li>
                                                        <a
                                                            href={twitter}
                                                            aria-label="Twitter"
                                                            target="_blank"
                                                            className="Twitter"
                                                            title="Twitter"
                                                            rel="noopener noreferrer"
                                                        >
                                                            <i className="fab fa-twitter"></i>
                                                        </a>
                                                    </li>
                                                )}
                                                {youtube && (
                                                    <li>
                                                        <a
                                                            href={youtube}
                                                            target="_blank"
                                                            aria-label="YouTube"
                                                            className="YouTube"
                                                            title="YouTube"
                                                            rel="noopener noreferrer"
                                                        >
                                                            <i className="fab fa-youtube"></i>
                                                        </a>
                                                    </li>
                                                )}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="widget widget_text cdb-top-bar-widget">
                                    <div className="cdb-top-bar-widget-inner">
                                        <div className="textwidget">
                                            <div className="wpml-ls-statics-shortcode_actions wpml-ls wpml-ls-legacy-list-horizontal">
                                                <ul>
                                                    {translations.map(
                                                        (locale, index) => {
                                                            let href = makeLinkRelative(locale.href);

                                                            if(TRANSLATION_LINK_OVERRIDES[href]) {
                                                                href = TRANSLATION_LINK_OVERRIDES[href];
                                                            }

                                                            return (
                                                                <li
                                                                    className="wpml-ls-slot-shortcode_actions wpml-ls-item wpml-ls-item-fr wpml-ls-first-item wpml-ls-item-legacy-list-horizontal"
                                                                    key={index}
                                                                >
                                                                    <NextNavLink
                                                                        href={href}
                                                                    >
                                                                        <a aria-label="language">
                                                                            {
                                                                                LOCALE_NAME_FROM_LOCALE_CODE[
                                                                                    locale
                                                                                        .locale
                                                                                ][
                                                                                    locale
                                                                                        .locale
                                                                                ]
                                                                            }
                                                                        </a>
                                                                    </NextNavLink>
                                                                </li>
                                                            );
                                                        }
                                                    )}
                                                    <li className="wpml-ls-slot-shortcode_actions wpml-ls-item wpml-ls-item-fr wpml-ls-first-item wpml-ls-item-legacy-list-horizontal search-btn">
                                                        <div className="search-container">
                                                            <a
                                                                onClick={() => {
                                                                    setSearchOpen(
                                                                        !searchOpen
                                                                    );
                                                                }}
                                                            >
                                                                <i
                                                                    className={`fa fa-fw ${
                                                                        searchOpen
                                                                            ? 'fa-times'
                                                                            : 'fa-search'
                                                                    } `}
                                                                ></i>
                                                            </a>
                                                            <Transition
                                                                show={
                                                                    searchOpen
                                                                }
                                                                enter="transition-opacity"
                                                                enterFrom="opacity-0 translate-y--1"
                                                                enterTo="opacity-100 translate-y-0"
                                                                leave="transition-opacity"
                                                                leaveFrom="opacity-100"
                                                                leaveTo="opacity-0"
                                                            >
                                                                <BlogSearchWidget
                                                                    locale={
                                                                        locale
                                                                    }
                                                                />
                                                            </Transition>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                ''
            )}
        </>
    );
}

export default function Header(props) {
    const [headerFixed, setHeaderFixed] = useState(false);
    useEffect(() => {
        new WOW().init();
        let el = document.querySelector('.cdb-header');
        let elMask = document.querySelector('.header-mask');
        function onWindowScroll() {
            if (window.pageYOffset > 58) {
                setHeaderFixed(true);
                el.classList.add('cdb-header-fixed');
                elMask.classList.remove('hidden');
            } else {
                setHeaderFixed(false);
            }
        }
        window.addEventListener('scroll', onWindowScroll);
        return function () {
            window.removeEventListener('scroll', onWindowScroll);
        };
    }, []);

    const template = props.template.templateName;
    const { menus, language, locale, options } = props;

    let menuItems = [];
    let logo = options?.topNavigation?.logo?.sourceUrl;
    let ctaButton = options?.topNavigation?.ctaButton;

    if (locale.locale === 'en_US') {
        ctaButton = options?.topNavigation?.ctaButton;
    } else {
        ctaButton = options?.topNavigation?.ctaButtonFr;
    }

    let translations = props.translations;
    if (['blog', 'tag', 'category'].includes(props.viewType)) {
        translations = getTranslationsFromArchiveUri(props.uri);
    }

    if (menus[language][TRANSLATION_MENU_KEY_NAME[language]['header']]) {
        menuItems =
            menus[language][TRANSLATION_MENU_KEY_NAME[language]['header']]
                .menuItems;
    }
    
    return (
        <>
            <header
                className={`cdb-header cdb-header-1 header-${template} ${
                    headerFixed || template === 'Landing Page'
                        ? 'cdb-header-fixed'
                        : ''
                }`}
            >
                <ExampleTranslationPicker
                    locale={locale}
                    language={language}
                    translations={translations}
                    options={options}
                    template={template}
                />
                <ExampleMenu
                    menuItems={menuItems}
                    logo={logo}
                    options={options}
                    language={language}
                    locale={locale}
                    translations={translations}
                    ctaButton={ctaButton}
                    template={template}
                />
            </header>
            <div
                className={`header-mask header-${template} ${
                    headerFixed || template === 'Landing Page' ? '' : 'hidden'
                } `}
            ></div>
        </>
    );
}
