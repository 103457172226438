// TODO: Replace h3 tag content with incoming text with the correct language

import React from 'react';

import { ARCHIVE_LAYOUTS } from '../../util/i18n';
import NextNavLink from '../NextNavLink';

const BlogRecentPosts = ({ language, recentPosts, ...posts }) => {
    return (
        <div className="widget widget_recent_entries recent-posts-2">
            <h3 className="cdb-sidearea-title">
                {ARCHIVE_LAYOUTS['recent'][language]}
            </h3>
            <ul>
                {recentPosts.map((post) => (
                    <li key={post.id}>
                        <NextNavLink href={post.uri}>
                            <a title={post.title}>{post.title}</a>
                        </NextNavLink>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default BlogRecentPosts;
