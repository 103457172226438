import React from 'react';

import VisualComposerRowInner from '../components/visual-composer/VisualComposerRowInner';

function VisualComposerRowInnerShortcode(props) {
    return (
        <VisualComposerRowInner attr={props}>
            {props.children}
        </VisualComposerRowInner>
    );
}

export default VisualComposerRowInnerShortcode;
