// TODO: Check if the incoming settings have or not the sidebar and in which side we should render it

import React from 'react'
import BlogPostsWrapper from './BlogPostsWrapper';
import BlogSidebar from './BlogSidebar';

import { ARCHIVE_LAYOUTS } from '../../util/i18n';

const BlogContainer = ({blogArchiveData, language, recentPosts, posts, paginationInfo, locale, slug, viewType, ...props}) => {
    return (
        <div className={`cdb-blog cdb-blog-archive sidebar-position-right has-sidebar`} >
            <div className="container">
                <div className="row">
                    <BlogPostsWrapper language={language} posts={posts} paginationInfo={paginationInfo} viewType={viewType} slug={slug} />
                    <BlogSidebar language={language} locale={locale} categories={blogArchiveData.categories} tags={blogArchiveData.tags} recentPosts={recentPosts} />
                </div>
            </div>
        </div>
    )
}

export default BlogContainer
