import React from 'react';

import VisualComposerColumnInner from '../components/visual-composer/VisualComposerColumnInner';

function VisualComposerColumnInnerShortcode(props) {
    return (
        <VisualComposerColumnInner attr={props}>
            {props.children}
        </VisualComposerColumnInner>
    );
}

export default VisualComposerColumnInnerShortcode;
