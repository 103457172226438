import React from 'react';
import Head from 'next/head';

function VisualComposerSpacer({ el_class, height = '32px', css, el_id }) {
    return (
        <>
            {css ? (
                <Head>
                    <style type="text/css">{`
                    ${css && css}
                `}</style>
                </Head>
            ) : (
                ''
            )}
            <div
                id={el_id}
                className={`vc_empty_space ${
                    css ? css.split('{')[0].substring(1) : ''
                } ${el_class ? el_class : ''}`}
                style={{
                    height: height.search('px') != -1 ? height : `${height}px`
                }}
            ></div>
        </>
    );
}

export default VisualComposerSpacer;
