import React from 'react';
import Head from 'next/head';

import Slider from 'react-slick';

function SampleNextArrow(props) {
    const { onClick } = props;
    return (
        <div className="next" onClick={onClick}>
            <span className="ti-angle-right"></span>
        </div>
    );
}

function SamplePrevArrow(props) {
    const { onClick } = props;
    return (
        <div className="prev" onClick={onClick}>
            <span className="ti-angle-left"></span>
        </div>
    );
}

function VisualComposerImagesCarousel({
    el_class,
    css,
    el_id = '',
    images,
    autoplay = false,
    img_size,
    wrap,
    speed = 500,
    hide_pagination_control = true,
    hide_prev_next_buttons = true,
    slides_per_view = 1,
    title
}) {
    const settings = {
        autoplay: autoplay,
        dots: hide_pagination_control,
        infinite: wrap,
        speed: speed,
        slidesToShow: slides_per_view,
        slidesToScroll: slides_per_view,
        nextArrow: <SampleNextArrow className="next-slide" />,
        prevArrow: <SamplePrevArrow className="prev-slide" />,
        responsive: [
            {
                breakpoint: 998,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <>
            {css ? (
                <Head>
                    <style type="text/css">{`
                    ${css && css}
                `}</style>
                </Head>
            ) : (
                ''
            )}
            <div
                id={el_id}
                className={`wpb_images_carousel wpb_content_element vc_clearfix ${
                    el_class ? el_class : ''
                }`}
            >
                <div className="wpb_wrapper">
                    {title && (
                        <h2 className="wpb_heading wpb_gallery_heading">
                            {title}
                        </h2>
                    )}
                    <Slider {...settings}>
                        {images.map((item, i) => (
                            <div key={i}>
                                <img src={item.url} alt={item.title} />
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>
        </>
    );
}

export default VisualComposerImagesCarousel;
