import React, { useState } from 'react';
import Head from 'next/head';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
    AccordionItemState
} from 'react-accessible-accordion';
function VisualComposerAccordion(originalProps) {
    const [activeItem, setActiveItem] = useState(null);

    let props = {
        ...originalProps
    };
    props = props.attr;

    return (
        <>
            {props?.css ? (
                <Head>
                    <style type="text/css">{`
                    ${props?.css && props?.css}
                `}</style>
                </Head>
            ) : (
                ''
            )}
            <div
                className="vc_tta-container"
                id={props.el_id ? props.el_id : ''}
            >
                {props.title && <h2>{props.title}</h2>}

                <div
                    className={`vc_general vc_tta vc_tta-accordion ${
                        props.css ? props.css.split('{')[0].substring(1) : ''
                    } ${
                        props.spacing ? `vc_tta-spacing-${props.spacing}` : ''
                    } vc_tta-color-${
                        props.color ? props.color : 'grey'
                    } vc_tta-style-${
                        props.style ? props.style : 'classic'
                    } vc_tta-shape-${props.shape ? props.shape : 'rounded'} ${
                        !props.gap && !props.spacing
                            ? 'vc_tta-o-shape-group'
                            : ''
                    } ${
                        props.gap ? `vc_tta-gap-${props.gap}` : ''
                    } vc_tta-controls-align-${
                        props.c_align ? props.c_align : 'left'
                    } ${
                        props.css ? props.css.split('{')[0].substring(1) : ''
                    } ${props.no_fill ? 'vc_tta-o-no-fill' : ''} ${
                        props.el_class ? props.el_class : ''
                    }`}
                >
                    <div className="vc_tta-panels-container">
                        <div className="vc_tta-panels">
                            <Accordion
                                preExpanded={
                                    props.children.length > 1 ? [0, 1] : []
                                }
                                allowZeroExpanded
                            >
                                {props.children.map((item, i) => {
                                    return (
                                        <div
                                            key={i}
                                            className={`vc_tta-panel ${
                                                activeItem === i
                                                    ? 'vc_active'
                                                    : ''
                                            }`}
                                            id={props.el_id ? props.el_id : ''}
                                        >
                                            <AccordionItem
                                                uuid={i === 0 ? 1 : 0}
                                                key={i}
                                            >
                                                <AccordionItemHeading>
                                                    <AccordionItemButton>
                                                        <div className="vc_tta-panel-heading">
                                                            <h4
                                                                className={`vc_tta-panel-title vc_tta-controls-icon-position-${
                                                                    props.c_position
                                                                        ? props.c_position
                                                                        : 'right'
                                                                }`}
                                                            >
                                                                <a>
                                                                    {item.props
                                                                        .add_icon && (
                                                                        <i
                                                                            className={`vc_tta-icon ${
                                                                                item
                                                                                    .props
                                                                                    .i_icon_themify ||
                                                                                item
                                                                                    .props
                                                                                    .i_icon_typicons ||
                                                                                item
                                                                                    .props
                                                                                    .i_icon_fontawesome ||
                                                                                item
                                                                                    .props
                                                                                    .i_icon_openiconic ||
                                                                                item
                                                                                    .props
                                                                                    .i_icon_entypo ||
                                                                                item
                                                                                    .props
                                                                                    .i_icon_linecons ||
                                                                                item
                                                                                    .props
                                                                                    .i_icon_monosocial ||
                                                                                item
                                                                                    .props
                                                                                    .i_icon_material
                                                                            }`}
                                                                        ></i>
                                                                    )}

                                                                    <span className="vc_tta-title-text">
                                                                        {
                                                                            item
                                                                                .props
                                                                                .title
                                                                        }
                                                                    </span>
                                                                    <AccordionItemState>
                                                                        {({
                                                                            expanded
                                                                        }) =>
                                                                            expanded ? (
                                                                                <>
                                                                                    <i className="vc_tta-controls-icon fas fa-minus"></i>
                                                                                    {setActiveItem(
                                                                                        i
                                                                                    )}
                                                                                </>
                                                                            ) : (
                                                                                <i className="vc_tta-controls-icon fas fa-plus"></i>
                                                                            )
                                                                        }
                                                                    </AccordionItemState>
                                                                </a>
                                                            </h4>
                                                        </div>
                                                    </AccordionItemButton>
                                                </AccordionItemHeading>
                                                <AccordionItemPanel>
                                                    {item.props.children ? (
                                                        <div className="vc_tta-panel-body">
                                                            {
                                                                item.props
                                                                    .children
                                                            }
                                                        </div>
                                                    ) : (
                                                        <div
                                                            className="vc_tta-panel-body"
                                                            dangerouslySetInnerHTML={{
                                                                __html:
                                                                    item.props
                                                                        .content
                                                            }}
                                                        />
                                                    )}
                                                </AccordionItemPanel>
                                            </AccordionItem>
                                        </div>
                                    );
                                })}
                            </Accordion>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default VisualComposerAccordion;
