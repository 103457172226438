import React from 'react';

const FooterSocialItems = ({ title, socialItems, ...props }) => {
    let facebook = socialItems?.facebook;
    let linkedin = socialItems?.linkedin;
    let twitter = socialItems?.twitter;
    let youtube = socialItems?.youtube;

    return (
        <div className="widget widget_text">
            <h4 className="cdb-widget-title">{title}</h4>
            <div className="textwidget">
                <ul className="cdb-social-links colored">
                    {facebook && (
                        <li>
                            <a
                                href={facebook}
                                target="_blank"
                                className="Facebook"
                                title="Facebook"
                                rel="noopener noreferrer"
                            >
                                <i className="fab fa-facebook-f"></i>
                            </a>
                        </li>
                    )}
                    {linkedin && (
                        <li>
                            <a
                                href={linkedin}
                                target="_blank"
                                className="LinkedIn"
                                title="LinkedIn"
                                rel="noopener noreferrer"
                            >
                                <i className="fab fa-linkedin-in"></i>
                            </a>
                        </li>
                    )}
                    {twitter && (
                        <li>
                            <a
                                href={twitter}
                                target="_blank"
                                className="Twitter"
                                title="Twitter"
                                rel="noopener noreferrer"
                            >
                                <i className="fab fa-twitter"></i>
                            </a>
                        </li>
                    )}
                    {youtube && (
                        <li>
                            <a
                                href={youtube}
                                target="_blank"
                                className="YouTube"
                                title="YouTube"
                                rel="noopener noreferrer"
                            >
                                <i className="fab fa-youtube"></i>
                            </a>
                        </li>
                    )}
                </ul>
            </div>
        </div>
    );
};

export default FooterSocialItems;
