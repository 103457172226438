import React from 'react';
import NextNavLink from './../NextNavLink';

const VisualComposerPostGridItem = ({ colWidth, post, pageProps }) => {
    const { featured_image, title, excerpt } = post;
    const uri = pageProps.language === 'en' ? post.uri : '/fr' + post.uri;

    return (
        <div
            className={`vc_grid-item vc_clearfix vc_col-sm-${colWidth} vc_grid-item-zone-c-bottom vc_visible-item`}
        >
            <div className={`vc_grid-item-mini vc_clearfix`}>
                <div className="vc_gitem-animated-block">
                    <div
                        className="vc_gitem-zone vc_gitem-zone-a vc_gitem-is-link"
                        style={{
                            backgroundImage: `url(${
                                featured_image ? featured_image : ''
                            })`
                        }}
                    >
                        <NextNavLink href={uri}>
                            <a
                                title={title}
                                className="vc_gitem-link vc-zone-link"
                            ></a>
                        </NextNavLink>
                        <img
                            src={featured_image ? featured_image : ''}
                            className="vc_gitem-zone-img"
                            alt=""
                        />
                        <div className="vc_gitem-zone-mini"></div>
                    </div>
                </div>
                <div
                    className="vc_gitem-zone vc_gitem-zone-c"
                    style={{ backgroundColor: '#f4f4f4' }}
                >
                    <div className="vc_gitem-zone-mini">
                        <div className="vc_gitem_row vc_row vc_gitem-row-position-top">
                            <div className="vc_col-sm-12 vc_gitem-col vc_gitem-col-align-">
                                <div className="vc_custom_heading vc_gitem-post-data vc_gitem-post-data-source-post_title">
                                    <h4>{title}</h4>
                                </div>
                                <div className="vc_custom_heading vc_gitem-post-data vc_gitem-post-data-source-post_excerpt">
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: excerpt
                                        }}
                                    ></div>
                                    <p></p>
                                </div>
                                <div className="vc_btn3-container vc_btn3-left">
                                    <NextNavLink href={uri}>
                                        <a
                                            className="vc_general vc_btn3 vc_btn3-size-md vc_btn3-shape-rounded vc_btn3-style-flat vc_btn3-color-juicy-pink"
                                            a=""
                                            title={pageProps.language === 'en' ? 'Read more' : 'Lire plus'}
                                        >
                                            {pageProps.language === 'en' ? 'Read more' : 'Lire plus'}
                                        </a>
                                    </NextNavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="vc_clearfix"></div>
        </div>
    );
};

export default VisualComposerPostGridItem;
