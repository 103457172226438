import React from 'react';

const VisualComposerSeparator = ({
    align = 'center',
    borderWidth,
    className = '',
    idName = '',
    elWidth = '100'
}) => {

    const borderWidthCase = (arg) => {
        switch (arg) {
            case 8:
                return '10px'
            case 10:
                return '12px'
            case 15:
                return '17px'
            case 20:
                return '22px'
            default:
                return '14px'
        }
    }

    return (
        <div
            className={`vc-zigzag-wrapper vc_zigzag-align-${align} ${className}`}
            id={idName}
        >
            <div
                className="vc-zigzag-inner"
                style={{
                    width: `${elWidth}%`,
                    minHeight: borderWidthCase(borderWidth),
                    background: `0 repeat-x url('data:image/svg+xml;utf-8,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%3C%21DOCTYPE%20svg%20PUBLIC%20%22-%2F%2FW3C%2F%2FDTD%20SVG%201.1%2F%2FEN%22%20%22http%3A%2F%2Fwww.w3.org%2FGraphics%2FSVG%2F1.1%2FDTD%2Fsvg11.dtd%22%3E%3Csvg%20width%3D%2222px%22%20height%3D%2220px%22%20viewBox%3D%220%200%2018%2015%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%3Cpolygon%20id%3D%22Combined-Shape%22%20fill%3D%22%23ebebeb%22%20points%3D%228.98762301%200%200%209.12771969%200%2014.519983%209%205.40479869%2018%2014.519983%2018%209.12771969%22%3E%3C%2Fpolygon%3E%3C%2Fsvg%3E')`
                }}
            ></div>
        </div>
    );
};

export default VisualComposerSeparator;
