import React from 'react';

import CBAnimatedCounter from '../components/visual-composer/CBAnimatedCounter';

function CBAnimatedCounterShortcode(props) {
    const {
        add_icon,
        size,
        align,
        color_scheme,
        value,
        label,
        el_class,
        text_color,
        icon,
        icon_color,
        icon_size
    } = props;
    return (
        <CBAnimatedCounter
            add_icon={add_icon}
            size={size}
            align={align}
            color_scheme={color_scheme}
            value={value}
            label={label}
            el_class={el_class}
            text_color={text_color}
            icon={icon}
            icon_color={icon_color}
            icon_size={icon_size}
        />
    );
}

export default CBAnimatedCounterShortcode;
