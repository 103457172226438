import React from 'react';
import CBPricingTable from './../components/visual-composer/CBPricingTable';

const CBPricingTableShortcode = ({ content, ...props }) => {
    const {
        show_icon,
        icon_style,
        icon,
        custom_icon,
        title,
        subtitle,
        price,
        currency,
        price_period,
        description,
        price_color,
        button_link,
        button_text,
        el_class,
        meta_properties
    } = props;

    return (
        <>
            <CBPricingTable
                showIcon={show_icon}
                iconStyle={icon_style}
                icon={icon}
                customIcon={custom_icon}
                title={title}
                subtitle={subtitle}
                price={price}
                currency={currency}
                pricePeriod={price_period}
                description={description}
                priceColor={price_color}
                buttonLink={button_link}
                buttonText={button_text}
                meta_properties={meta_properties}
                className={el_class}
                content={content}
            />
        </>
    );
};

export default CBPricingTableShortcode;
