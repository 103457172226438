import React from 'react';
import NextNavLink from './../NextNavLink';

function Pagination({ currentPage, totalItems, pageSize = 10, getPageLink }) {
    /* The page set will always have the first page as its first item. */
    const pageSet = [1];
    const pages = Math.ceil(eval(totalItems) / pageSize);

    if (pages < 2) {
        return null;
    }

    if (currentPage > 3) {
        pageSet.push('dots');
    }

    if (currentPage > 2 && currentPage - 2 !== 1) {
        pageSet.push(currentPage - 2);
    }

    if (currentPage > 1 && currentPage - 1 !== 1) {
        pageSet.push(currentPage - 1);
    }

    if (currentPage !== 1 && currentPage !== pages) {
        pageSet.push(currentPage);
    }

    if (currentPage + 1 < pages) {
        pageSet.push(currentPage + 1);
    }

    if (currentPage + 2 < pages) {
        pageSet.push(currentPage + 2);
    }

    if (currentPage + 3 < pages) {
        pageSet.push('dots');
    }

    pageSet.push(pages);

    const PreviousPaginationLink = getPageLink(currentPage - 1, 'prev');
    const NextPaginationLink = getPageLink(currentPage + 1, 'next');

    /* Return markup reflecting original site's pagination markup */
    return (
        <div className="text-center cdb-pagination">
            <ul className="page-numbers">
                {currentPage > 1 ? (
                    <li><PreviousPaginationLink /></li>
                ) : null}
                {pageSet.map((pageNumber, i) => {
                    const PaginationLink = getPageLink(pageNumber); 

                    return (
                        pageNumber === currentPage ? (
                            <li key={i}>
                                <span
                                    aria-current="page"
                                    className="page-numbers current"
                                >
                                    {pageNumber}
                                </span>
                            </li>
                        ) : pageNumber === 'dots' ? (
                            <li key={i}>
                                <span className="page-numbers dots">…</span>
                            </li>
                        ) : (
                            <li key={i}>
                                <PaginationLink />
                            </li>
                        )
                    );
                })}
                {currentPage < pages ?
                <li>
                    <NextPaginationLink /> </li> : null}
            </ul>
        </div>
    );
}

export default Pagination;
