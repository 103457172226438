import React, { useState, useEffect } from 'react';
import { InlineShareButtons } from 'sharethis-reactjs';
import BackIcon from './../../images/icons/backIcon.svg';
import MenuIcon from './../../images/icons/menuIcon.svg';
import NextIcon from './../../images/icons/nextIcon.svg';

const PostDrawer = ({
    thumbnail = {},
    date,
    category,
    title = '',
    content,
    uri,
    openGraph,
    previousPost,
    nextPost
}) => {
    const image = thumbnail?.node.mediaItemUrl
        ? {
              id: thumbnail.node.id,
              mediaItemId: thumbnail.node.id,
              mediaItemUrl: thumbnail.node.mediaItemUrl,
              title: thumbnail.node.title,
              sizes: null,
              srcSet: null,
              caption: thumbnail.node.caption
          }
        : null;

    // States
    const [parsedDate, setParsedDate] = useState(null);
    const [parsedMonth, setParsedMonth] = useState(null);
    const [completeUrl, setCompleteUrl] = useState(null);

    useEffect(() => {
        if (date) {
            let unixDate = Date.parse(date); //This pass the ISOString to unix
            let dateParsed = new Date(unixDate); //This pass the unix to date

            setParsedDate(dateParsed.getDate());
            setParsedMonth(
                dateParsed.toLocaleString('default', { month: 'short' })
            );
        }
    }, [date]);

    useEffect(() => {
        if (window !== undefined) {
            setCompleteUrl(`${window.location.href}`);
        }
    }, [completeUrl]);

    return (
        <div className="col-md-9 cdb-post-container">
            {image ? (
                <div className="cdb-post-single-thumbnail">
                    <img
                        width="740"
                        height="372"
                        src={image.mediaItemUrl}
                        alt=""
                        className="attachment-codebean-blog-large size-codebean-blog-large wp-post-image"
                    />
                </div>
            ) : null}
            <aside className="cdb-post-date">
                <span className="day">{parsedDate}</span>
                <span className="month">{parsedMonth}</span>
            </aside>
            <div className="cdb-blog-content-inner">
                <header className="cdb-post-header">
                    <div className="cdb-post-meta">
                        <span className="cdb-post-meta-comments">
                            No Comments
                        </span>
                        {category?.name ? (
                            <span className="cdb-post-meta-category">
                                <a
                                    href={`/category/${category.slug}`}
                                    rel="category tag"
                                >
                                    {category.name}
                                </a>
                            </span>
                        ) : (
                            ''
                        )}
                    </div>
                    {title.length > 0 ? (
                        <h1 className="cdb-post-title">{title}</h1>
                    ) : (
                        ''
                    )}
                </header>
                <div
                    className="post-content clearfix"
                    dangerouslySetInnerHTML={{ __html: content }}
                ></div>
            </div>
            <footer className="post-single-footer clearfix">
                <div className="cdb-post-share">
                    <span className="post-share-title">Share:</span>
                    <ul className="cdb-social-links rounded colored-bg">
                        <InlineShareButtons
                            config={{
                                alignment: 'center', // alignment of buttons (left, center, right)
                                color: 'social', // set the color of buttons (social, white)
                                enabled: true, // show/hide buttons (true, false)
                                font_size: 20, // font size for the buttons
                                labels: null, // (cta, counts, null)
                                language: 'en', // which language to use (see LANGUAGES)
                                networks: [
                                    'facebook',
                                    'twitter',
                                    'linkedin',
                                    'tumblr',
                                    'email' //Not working
                                ],
                                padding: 10, // padding within buttons (INTEGER)
                                radius: 50, // the corner radius on each button (INTEGER)
                                show_total: false,
                                size: 40, // the size of each button (INTEGER)

                                // OPTIONAL PARAMETERS
                                url: completeUrl, // (defaults to current url)
                                image: openGraph, // (defaults to og:image or twitter:image)
                                title // (defaults to og:title or twitter:title)
                            }}
                        />
                    </ul>
                </div>
            </footer>
            <div className="cdb-post-nav">
                {nextPost ? (
                    <div className="cdb-post-nav-btn prev-btn">
                        <a
                            href={nextPost.uri}
                            className="cdb-post-nav-link prev"
                            aria-label="previous post"
                        >
                            <BackIcon />
                            <span>Newer</span>
                            <strong>{nextPost.title}</strong>
                        </a>
                    </div>
                ) : null}
                <div className="back-link">
                    <a
                        href="/blog/"
                        data-toggle="tooltip"
                        data-placement="top"
                        title=""
                        data-original-title="Back to list"
                        aria-label="back"
                    >
                        <MenuIcon />
                    </a>
                </div>
                {previousPost ? (
                    <div className="cdb-post-nav-btn next-btn">
                        <a
                            href={previousPost.uri}
                            className="cdb-post-nav-link next"
                            aria-label="next post"
                        >
                            <NextIcon />
                            <span>Older</span>
                            <strong>{previousPost.title}</strong>
                        </a>
                    </div>
                ) : null}
            </div>
        </div>
    );
};

export default PostDrawer;
