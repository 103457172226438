import React, { useState, useEffect } from 'react';
import BlogPostThumbnail from './BlogPostThumbnail';

import NextNavLink from '../NextNavLink';

// Thirdparty plugins
import Masonry from 'react-masonry-css';
import Head from 'next/head';
import Pagination from './../Pagination/index';

const BlogPostsWrapper = ({ posts, language, paginationInfo, viewType, slug, ...props }) => {
    const breakpointColumnsObj = {
        default: 2,
        1100: 2,
        768: 1,
        500: 1
    };

    const getPaginationLink = (number, type) => ({ children }) => {
        let href = '#';
        const pathPrefix = viewType === 'blog'
            ?
                language === 'en' 
                ? `/blog`
                : `/fr/blogue`
            :
                language === 'en'
                ? `/${viewType}/${slug}`
                : `/fr/${viewType}/${slug}`;
        if(number === 1) {
            href = pathPrefix;
        } else {
            href = `${pathPrefix}/page/${number}`
        }

        switch(type) {
            case 'next':
                return (
                    <NextNavLink href={href}>
                        <a
                            aria-label="pagination"
                            className="next page-numbers"
                        >
                            <i className="fas fa-angle-right"></i>
                        </a>
                    </NextNavLink>
                );
                break;

            case 'prev':
                return (
                    <NextNavLink href={href}>
                        <a
                            aria-label="pagination"
                            className="prev page-numbers"
                        >
                            <i className="fas fa-angle-left"></i>
                        </a>
                    </NextNavLink>
                );
                break;
        }

        return (
            <NextNavLink href={href}>
                <a
                    aria-label="pagination"
                    className="page-numbers"
                >
                    {number}
                </a>
            </NextNavLink>
        );
    };

    // States
    // loading State
    const [loaded, setLoaded] = useState(null);

    useEffect(() => {
        setLoaded(false);
    }, []);

    useEffect(() => {
        setLoaded(true);
    }, [posts]);

    return (
        <div className={`cdb-column cdb-posts-wrapper`}>
            {loaded ? (
                ''
            ) : (
                <div className="cdb-preloader">Loading posts...</div>
            )}
            <Masonry
                breakpointCols={breakpointColumnsObj}
                id="cdb-blog-posts"
                className="my-masonry-grid"
                className={`cdb-blog-posts clearfix two-columns pagination-regular ${
                    loaded ? '' : 'cdb-loaded'
                }`}
                columnClassName="post post-masonry type-post status-publish format-standard has-post-thumbnail hentry category-cyber-security-awareness"
            >
                {posts.map((post) => (
                    <BlogPostThumbnail
                        key={post.id}
                        featuredImage={post.featuredImage}
                        title={post.title}
                        date={post.date}
                        categories={post.categories}
                        excerpt={post.excerpt}
                        uri={post.uri}
                        language={language}
                    />
                ))}
            </Masonry>
            <Pagination currentPage={paginationInfo.currentPage} totalItems={paginationInfo.offsetPagination.total} getPageLink={getPaginationLink}/>
        </div>
    );
};

export default BlogPostsWrapper;
