import React from 'react';

import VisualComposerTextSeparator from './../components/visual-composer/VisualComposerTextSeparator';

const VisualComposerTextSeparatorShortcode = (props) => {
    const {
        align,
        border_width,
        color,
        el_class,
        el_id,
        el_width,
        style,
        title,
        title_align
    } = props;

    return (
        <VisualComposerTextSeparator
            align={align}
            borderWidth={border_width}
            color={color}
            className={el_class}
            idName={el_id}
            style={style}
            elWidth={el_width}
            title={title}
            titleAlign={title_align}
        />
    );
};

export default VisualComposerTextSeparatorShortcode;
