import React from 'react';
import Head from 'next/head';

const VisualComposerHoverbox = ({
    content,
    elClass,
    hoverTitle,
    imageId,
    pageProps,
    shape,
    css,
    meta_properties
}) => {
    const image = meta_properties.url ? {
        id: meta_properties.id,
        mediaItemId: meta_properties.id,
        mediaItemUrl: meta_properties.url,
        title: meta_properties.title,
        sizes: null,
        srcSet: null,
        caption: meta_properties.caption 
    } : {};

    return (
        <>
            {css ? (
                <Head>
                    <style>{css}</style>
                </Head>
            ) : (
                ''
            )}
            <div
                className={`vc-hoverbox-wrapper vc-hoverbox-width--100 vc-hoverbox-align--center vc-hoverbox-direction--default ${
                    elClass && elClass
                } ${shape && `vc-hoverbox-shape--${shape}`}  ${css ? css.split('{')[0].substring(1) : ''} `}
            >
                <div
                    className="vc-hoverbox"
                    style={{ perspective: '1426.62px' }}
                >
                    <div
                        className="vc-hoverbox-inner"
                        style={{ minHeight: '324px' }}
                    >
                        <div
                            data-bg={`url(${image.mediaItemUrl})`}
                            className="vc-hoverbox-block vc-hoverbox-front"
                            style={{
                                backgroundImage: `url(${image.mediaItemUrl})`
                            }}
                            data-was-processed="true"
                        >
                            <div className="vc-hoverbox-block-inner vc-hoverbox-front-inner"></div>
                        </div>
                        <div
                            className="vc-hoverbox-block vc-hoverbox-back"
                            style={{ backgroundColor: '#ebebeb' }}
                        >
                            <div className="vc-hoverbox-block-inner vc-hoverbox-back-inner">
                                <h2 style={{ textAlign: 'center' }}>
                                    {hoverTitle}
                                </h2>
                                <div
                                    className={elClass && elClass}
                                    style={{ height: '156px' }}
                                    dangerouslySetInnerHTML={{
                                        __html: content
                                    }}
                                ></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default VisualComposerHoverbox;
