import React from 'react';
import Head from 'next/head';

function VisualComposerColumn(originalProps) {
    let props = {
        ...originalProps
    };
    let attributes = { ...originalProps.attr };

    return (
        <>
            {props?.attr?.css ? (
                <Head>
                    <style type="text/css">{`
                    ${props?.attr?.css && props?.attr?.css}
                `}</style>
                </Head>
            ) : (
                ''
            )}
            <div
                id={props.attr.el_id && props.attr.el_id}
                className={`wpb_column vc_column_container ${
                    props.attr.el_class ? props.attr.el_class : ''
                } ${
                    props.attr.css
                        ? props.attr.css.search('background') != -1 ||
                          props.attr.css.search('border') != -1
                            ? 'vc_col-has-fill'
                            : ''
                        : ''
                } ${props.attr.offset ? props.attr.offset : ''} vc_col-sm-${
                    props.attr.width
                        ? props.attr.width === '1/5' ||
                          props.attr.width === '2/5' ||
                          props.attr.width === '3/5' ||
                          props.attr.width === '4/5'
                            ? props.attr.width
                            : parseFloat(eval(props.attr.width)) * 12
                        : 12
                }`}
            >
                <div
                    className={`vc_column-inner ${
                        props.attr.css
                            ? props.attr.css.split('{')[0].substring(1)
                            : ''
                    }`}
                >
                    <div className="wpb_wrapper">{props.children}</div>
                </div>
            </div>
        </>
    );
}

export default VisualComposerColumn;
