import React from 'react';
import SearchResultBlock from './SearchResultBlock';
import Pagination from './../Pagination';

const SearchContainer = ({ results, paginationInfo, paginationHandler, language }) => {
    const { currentPage, hasMore, hasPrevious, total } = paginationInfo;

    return (
        <div className="page-padding search-results-wrapper">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        {results.map((result) => (
                            <SearchResultBlock
                                key={result.id}
                                id={result.id}
                                uri={result.uri}
                                imageSrc={result.imageSrc}
                                title={result.title}
                                language={language}
                                excerpt={result.excerpt}
                            />
                        ))}
                        <Pagination
                            currentPage={currentPage}
                            totalItems={total}
                            getPageLink={paginationHandler}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SearchContainer;
