import React from 'react';
import PostSidebar from './PostSidebar';
import PostDrawer from './PostDrawer';

const BlogContainer = ({
    postImage,
    postCategory,
    postTitle,
    postDate,
    postContent,
    postUri,
    postOg,
    locale,
    nextPost,
    previousPost,
    recentPosts,
    blogArchiveData,
}) => {
    return (
        <div className={`cdb-blog`}>
            <article
                className={`post post-single post-small-image post-single-type-4 post-14350 type-post status-publish format-standard has-post-thumbnail hentry category-cyber-security-awareness`}
            >
                <div className="container">
                    <div className="row">
                        <PostDrawer
                            thumbnail={postImage}
                            date={postDate}
                            category={postCategory}
                            title={postTitle}
                            content={postContent}
                            uri={postUri}
                            openGraph={postOg}
                            nextPost={nextPost}
                            previousPost={previousPost}
                        />
                        <PostSidebar
                            locale={locale}
                            categories={blogArchiveData.categories}
                            tags={blogArchiveData.tags}
                            recentPosts={recentPosts}
                        />
                    </div>
                </div>
            </article>
        </div>
    );
};

export default BlogContainer;
