import React from 'react';

import VisualComposerVideo from '../components/visual-composer/VisualComposerVideo';

function VisualComposerVideoShortCode(props) {
    const {
        el_class,
        css,
        el_id,
        el_width,
        link,
        el_aspect,
        align,
        title
    } = props;
    return (
        <VisualComposerVideo
            el_class={el_class}
            el_id={el_id}
            css={css}
            el_width={el_width}
            link={link}
            el_aspect={el_aspect}
            align={align}
            title={title}
        />
    );
}

export default VisualComposerVideoShortCode;
