import React, { useEffect, useState } from 'react';
import Head from 'next/head';

function CBCountDownTimer({
    size = 'medium',
    date,
    style = 'standard',
    align = 'left',
    el_class
}) {
    const calculateTimeLeft = () => {
        const difference = new Date(date) - new Date();
        let timeLeft = {};

        if (difference > 0) {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                min: Math.floor((difference / 1000 / 60) % 60),
                sec: Math.floor((difference / 1000) % 60)
            };
        }

        return timeLeft;
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
    const [year] = useState(new Date().getFullYear());

    useEffect(() => {
        setTimeout(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);
    });

    const timerComponents = [];

    Object.keys(timeLeft).forEach((interval, i) => {
        if (!timeLeft[interval]) {
            return;
        }
        timerComponents.push(
            <span className={`countdown-${interval}`}>
                <span className="number">{timeLeft[interval]} </span>
                <span className="text">{interval}</span>
            </span>
        );
    });

    return (
        <>
            <div
                className={`cdb-countdown-timer-holder ${size} ${align} ${style} ${
                    el_class ? el_class : ''
                }`}
            >
                <div className="cdb-countdown-timer">
                    {timerComponents.length ? (
                        timerComponents
                    ) : (
                        <>
                            <span className="countdown-days">
                                <span className="number">0 </span>
                                <span className="text">days</span>
                            </span>
                            <span className="countdown-hours">
                                <span className="number">00 </span>
                                <span className="text">hours</span>
                            </span>
                            <span className="countdown-min">
                                <span className="number">00</span>
                                <span className="text">min</span>
                            </span>
                            <span className="countdown-sec">
                                <span className="number">00 </span>
                                <span className="text">sec</span>
                            </span>
                        </>
                    )}
                </div>
            </div>
        </>
    );
}
export default CBCountDownTimer;
