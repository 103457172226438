import React from 'react';

const VisualComposerTabsOption = ({ 
    id, 
    active, 
    title, 
    activeHandler,
    i_position = "right",
    add_icon = false,
    i_icon_fontawesome = "",
    ...props 
}) => {
    function renderIcon(side) {
        if(!add_icon || add_icon !== "true") {
            return null;
        }

        if(i_position !== side) {
            return null;
        }

        return (
            <i className={`vc_tta-icon ${i_icon_fontawesome}`} />
        );
    }

    return (
        <li className={`vc_tta-tab ${active ? 'vc_active' : ''}`}>
            <a href={`#${id}`} onClick={(e) => {
                e.preventDefault();
                activeHandler(id)
            }}>
                {renderIcon('left')}
                <span className="vc_tta-title-text">{title}</span>
                {renderIcon('right')}
            </a>
        </li>
    );
};

export default VisualComposerTabsOption;
