import Link from 'next/link';
import { useRouter } from 'next/router';

/* 
 * This is a utility class used to inject active class into a Bootstrap
 * nav link for initial prototype: may be reusable elsewhere.
 */
const NextNavLink = ({ href, children, passHref }) => {
    const router = useRouter();

    let className = children.props.className || '';
    if (router.pathname === href) {
        className = `${className} active`;
    }

    const voidLink = href && href.startsWith('#');
    
    return voidLink
        ? React.cloneElement(children, { className, href })
        : (
            <Link href={href} passHref={passHref}>
                {React.cloneElement(children, { className })}
            </Link>
        );
}

export default NextNavLink;