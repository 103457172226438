import React from 'react';
import FooterNavMenu from './FooterNavMenu';
import FooterSocialItems from './FooterSocialItems';
import FooterGetInTouch from './FooterGetInTouch';
import FooterForm from './FooterForm';

import { TRANSLATION_MENU_KEY_NAME, MENU_NAMES } from '../../util/i18n';

const FooterMenu = ({ language, lang, options, menus, ...props }) => {
    const trainingMenu =
        menus[language][TRANSLATION_MENU_KEY_NAME[language]['footer-training']];

    const platformMenu =
        menus[language][TRANSLATION_MENU_KEY_NAME[language]['footer-platform']];
    const freetoolsMenu =
        menus[language][
            TRANSLATION_MENU_KEY_NAME[language]['footer-free-tools']
        ];
    const companyMenu =
        menus[language][TRANSLATION_MENU_KEY_NAME[language]['footer-company']];

    return (
        <div
            className="cdb-footer-widgets"
            style={{ backgroundColor: '#292929' }}
        >
            <div className="container">
                <div className="row">
                    <div className="col-xs-12 col-sm-6 col-md-3">
                        <FooterNavMenu
                            title={MENU_NAMES[language]['footer-training']}
                            items={trainingMenu.menuItems}
                        />
                        <FooterNavMenu
                            title={MENU_NAMES[language]['footer-platform']}
                            items={platformMenu.menuItems}
                        />
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-3">
                        <FooterNavMenu
                            title={MENU_NAMES[language]['footer-free-tools']}
                            items={freetoolsMenu.menuItems}
                        />
                        {Object.values(options.socialLinks).length > 0 ? (
                            <FooterSocialItems
                                title={MENU_NAMES[language]['social']}
                                socialItems={options.socialLinks}
                            />
                        ) : (
                            ''
                        )}
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-3">
                        <FooterNavMenu
                            title={MENU_NAMES[language]['footer-company']}
                            items={companyMenu.menuItems}
                        />
                        {lang === 'en_US' ? (
                            options.footer.getInTouch ? (
                                <FooterGetInTouch
                                    title={MENU_NAMES[language]['contact-us']}
                                    data={options.footer.getInTouch}
                                />
                            ) : (
                                ''
                            )
                        ) : options.footer.getInTouch ? (
                            <>
                                <FooterGetInTouch
                                    title={MENU_NAMES[language]['contact-us']}
                                    data={
                                        lang === 'fr_FR'
                                            ? options.footer.getInTouchFr
                                                  .replace(
                                                      '1-866-889-5806',
                                                      '33 7 61 69 79 79'
                                                  )
                                                  .replace(
                                                      '1-866-889-5806',
                                                      '33 7 61 69 79 79'
                                                  )
                                            : options.footer.getInTouchFr
                                    }
                                />
                            </>
                        ) : (
                            ''
                        )}
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-3">
                        {lang === 'en_US' ? (
                            <FooterForm
                                form={options.footer.iframeUrl}
                                afterForm={options.footer.afterFooterForm}
                                beforeForm={options.footer.beforeFooterForm}
                            />
                        ) : (
                            <FooterForm
                                form={options.footer.iframeUrlFr}
                                afterForm={options.footer.afterFooterFormFr}
                                beforeForm={options.footer.beforeFooterFormFr}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FooterMenu;
