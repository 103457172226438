import React from 'react';
import Head from 'next/head';

const VisualComposerMessage = ({
    css,
    color,
    className = '',
    id = '',
    iconType = '',
    iconFontawesome = 'fa fa-info-circle',
    messageBoxColor = 'info',
    messageBoxStyle = 'standard',
    style = 'rounded',
    content,

    // icon variations
    pixelIcons,
    openIconic,
    typIcons,
    entypo,
    linecons,
    monosocial
}) => {
    return (
        <>
            {css ? (
                <Head>
                    <style>{css}</style>
                </Head>
            ) : (
                ''
            )}
            <div
                id={id}
                className={`
                vc_message_box 
                vc_message_box-${messageBoxStyle} 
                vc_message_box-${style} 
                vc_color-${messageBoxColor} 
                vc_color-${color} ${className} 
                ${css ? css.split('{')[0].substring(1) : ''}`}
            >
                <div className="vc_message_box-icon">
                    {iconType === '' ? <i className={iconFontawesome}></i> : ''}
                    {iconType === 'pixelicons' ? (
                        <i className={pixelIcons}></i>
                    ) : (
                        ''
                    )}
                    {iconType === 'openiconic' ? (
                        <i className={openIconic}></i>
                    ) : (
                        ''
                    )}
                    {iconType === 'typicons' ? (
                        <i className={typIcons}></i>
                    ) : (
                        ''
                    )}
                    {iconType === 'entypo' ? <i className={entypo}></i> : ''}
                    {iconType === 'linecons' ? (
                        <i className={linecons}></i>
                    ) : (
                        ''
                    )}
                    {iconType === 'monosocial' ? (
                        <i className={monosocial}></i>
                    ) : (
                        ''
                    )}
                </div>

                <div dangerouslySetInnerHTML={{ __html: content }}></div>

            </div>
        </>
    );
};

export default VisualComposerMessage;
