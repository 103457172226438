import React from 'react';
import Slider from 'react-slick';
import Head from 'next/head';

function SampleNextArrow(props) {
    const { onClick } = props;
    return (
        <div className="next" onClick={onClick}>
            <span className="ti-angle-right"></span>
        </div>
    );
}

function SamplePrevArrow(props) {
    const { onClick } = props;
    return (
        <div className="prev" onClick={onClick}>
            <span className="ti-angle-left"></span>
        </div>
    );
}

const CodebeanTestimonials = ({
    children,
    autoplay = false,
    borders,
    className = '',
    layout = 'grid', // This render a Row or a slider.
    align = 'center',
    colorScheme = 'light',
    columns = 1,
    showPaginationControl = false,
    showPrevNextButtons,
    slidesPerView = 1, //columns on slider
    wrap = false,
    css
}) => {
    // In case of slider
    const settings = {
        autoplay,
        dots: eval(showPaginationControl),
        infinite: wrap,
        speed: 500,
        slidesToShow: eval(slidesPerView),
        slidesToScroll: 1,
        className: className,
        nextArrow: <SampleNextArrow className="next-slide" />,
        prevArrow: <SamplePrevArrow className="prev-slide" />,
        responsive: [
            {
                breakpoint: 998,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <>
            {css ? (
                <Head>
                    <style>{css}</style>
                </Head>
            ) : (
                ''
            )}
            {layout === 'slider' ? (
                <div
                    className={`${
                        css ? css.split('{')[0].substring(1) : ''
                    } cdb-testimonials-holder terranova-testimonials`}
                >
                    <div
                        className={`testimonials clearfix testimonials-slider testimonials-${align} testimonials-${colorScheme} testimonials-bordered`}
                    >
                        <Slider {...settings}>{children}</Slider>
                    </div>
                </div>
            ) : (
                <div
                    className={`${
                        css ? css.split('{')[0].substring(1) : ''
                    } ${className} cdb-testimonials-holder`}
                >
                    <div
                        className={`testimonials clearfix testimonials-grid testimonials-${align} testimonials-${colorScheme} testimonials-columns-${columns}`}
                    >
                        {children}
                    </div>
                </div>
            )}
        </>
    );
};

export default CodebeanTestimonials;
