import React from 'react';
import TSHomeSlider from '../components/visual-composer/TSHomeSlider';

const TSHomeSliderShortcode = ({ children, ...props }) => {

    const { id, effect, arrows, dots, keyboard, mousewheel, autoplay, autoplayDelay, loop } = props;

    return (
        <>
            <TSHomeSlider
                id={id}
                effect={effect}
                arrows={arrows}
                dots={dots}
                loop={loop}
                autoplay={autoplay}
                autoplayDelay={autoplayDelay}
                keyboard={keyboard}
                mousewheel={mousewheel}
            >
                {children}
            </TSHomeSlider>
        </>
    );
};

export default TSHomeSliderShortcode;
