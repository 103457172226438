import React from 'react';

const SearchResultBlock = ({ id, uri, imageSrc, title, excerpt, language, ...props }) => {
    return (
        <div className="search-result-block">
            <div className="result-thumbnail">
                {imageSrc ? (
                    <a href={uri} title={title}>
                        <img
                            width="220"
                            height="220"
                            src={imageSrc}
                            className="attachment-codebean-search-thumbnail size-codebean-search-thumbnail wp-post-image lazyloaded"
                            alt={title}
                        />
                    </a>
                ) : (
                    ''
                )}
            </div>
            <div className="result-content">
                <h3 className="result-title">
                    <a href={uri} title={title}>
                        {title}
                    </a>
                </h3>

                {excerpt ? (
                    <div dangerouslySetInnerHTML={{ __html: excerpt }}></div>
                ) : (
                    ''
                )}

                <a href={uri} title={title} className="read-more">
                    {language === 'en' ? 'Read More' : 'Lire Plus'}
                </a>
            </div>
        </div>
    );
};

export default SearchResultBlock;
