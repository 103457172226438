import React from 'react';

import VisualComposerPostGrid from './../components/visual-composer/VisualComposerPostGrid';

const VisualComposerPostGridShortcode = ({ content, ...props }) => {
    const {
        post_type,
        max_items, //total items to show
        initial_loading_animation,
        grid_id,
        taxonomies,
        el_id,
        el_class,
        gap,
        element_width, //col-size
        pageProps,
        meta_properties
    } = props;

    return (
        <>
            {meta_properties.results.length > 0 ? (
                <VisualComposerPostGrid
                    postType={post_type}
                    maxItems={max_items}
                    initLoadingAnimation={initial_loading_animation}
                    gridId={grid_id}ç
                    taxonomies={taxonomies}
                    id={el_id}
                    className={el_class}
                    gap={gap}
                    colWidth={element_width}
                    pageProps={pageProps}
                    metaProperties={meta_properties}
                />
            ) : (
                'No results.'
            )}
        </>
    );
};

export default VisualComposerPostGridShortcode;
