import React from 'react';
import VisualComposerMessage from './../components/visual-composer/VisualComposerMessage';

const VisualComposerMessageShortcode = ({ content, ...props }) => {
    const {
        css,
        color,
        el_class,
        el_id,
        icon_type,
        icon_fontawesome,
        message_box_color,
        message_box_style,
        style,

        // Variants of Icons
        icon_pixelicons,
        icon_openiconic,
        icon_typicons,
        icon_entypo,
        icon_linecons,
        icon_monosocial
    } = props;

    return (
        <VisualComposerMessage
            css={css}
            color={color}
            className={el_class}
            id={el_id}
            iconType={icon_type}
            iconFontawesome={icon_fontawesome}
            messageBoxColor={message_box_color}
            messageBoxStyle={message_box_style}
            content={content}
            style={style}
            pixelIcons={icon_pixelicons}
            openIconic={icon_openiconic}
            typIcons={icon_typicons}
            entypo={icon_entypo}
            linecons={icon_linecons}
            monosocial={icon_monosocial}
        />
    );
};

export default VisualComposerMessageShortcode;
