import React from 'react';
import Head from 'next/head';

function CBAnimatedBox(originalProps) {
    let props = {
        ...originalProps
    };
    props = props.attr;
    return (
        <>
            {props.css ? (
                <Head>
                    <style type="text/css">{`
                    ${props.css && props.css}
                `}</style>
                </Head>
            ) : (
                ''
            )}
            <div
                className={`cdb-animated-box  wow fadeInUp  ${
                    props.css ? props.css.split('{')[0].substring(1) : ''
                } ${props.el_class ? props.el_class : ''}`}
            >
                {props.children}
            </div>
        </>
    );
}

export default CBAnimatedBox;
