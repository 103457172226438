import React from 'react';
import Head from 'next/head';
import ReactPlayer from 'react-player';

function VisualComposerVideo({
    el_class,
    css,
    el_id,
    el_width,
    link,
    el_aspect = 169,
    align = 'left',
    title
}) {
    return (
        <>
            {css ? (
                <Head>
                    <style type="text/css">{`
                    ${css && css}
                `}</style>
                </Head>
            ) : (
                ''
            )}
            <div
                className={`wpb_video_widget wpb_content_element vc_clearfix vc_video-aspect-ratio-${el_aspect} vc_video-el-width-${el_width} vc_video-align-${align}`}
            >
                <div className="wpb_wrapper">
                    {title && (
                        <h2 class="wpb_heading wpb_video_heading">{title}</h2>
                    )}
                    <div
                        id={el_id}
                        className={`wpb_video_wrapper ${
                            css ? css.split('{')[0].substring(1) : ''
                        } ${el_class ? el_class : ''}`}
                    >
                        {/* <iframe
                            loading="lazy"
                            src={link}
                            frameborder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen={true}
                        ></iframe> */}
                        <ReactPlayer controls={true} height="auto" url={link} />
                    </div>
                </div>
            </div>
        </>
    );
}

export default VisualComposerVideo;
