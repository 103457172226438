import React from 'react';

import VisualComposerSection from '../components/visual-composer/VisualComposerSection';

function VisualComposerSectionShortcode(props) {
    return (
        <VisualComposerSection attr={props}>
            {props.children}
        </VisualComposerSection>
    );
}

export default VisualComposerSectionShortcode;
