//TODO: Replace Form action to fire the search url

import React from 'react';
import BlogCategoriesList from './BlogCategoriesList';
import BlogTagsList from './BlogTagsList';
import BlogRecentPosts from './BlogRecentPosts';
import BlogSearchWidget from './BlogSearchWidget';

import { ARCHIVE_LAYOUTS } from '../../util/i18n';

const BlogSidebar = ({ categories, language, recentPosts, tags, locale, ...props }) => {
    return (
        <div className="cdb-column cdb-sidebar-area">
            <div className="cdb-sidebar-inner">
                <div className="widget widget_search search-2">
                    <h3 className="cdb-sidearea-title">
                        {ARCHIVE_LAYOUTS['search'][language]}
                    </h3>
                    
                    <BlogSearchWidget locale={locale}/>
                </div>
                
                <BlogCategoriesList language={language} categories={categories} />
                <BlogRecentPosts language={language} recentPosts={recentPosts} />
                <BlogTagsList language={language} tags={tags} />
            </div>
        </div>
    );
};

export default BlogSidebar;
