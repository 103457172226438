import React from 'react';
import NextNavLink from './../NextNavLink';

const BlogPostThumbnail = ({
    featuredImage,
    title,
    date,
    excerpt,
    uri,
    categories,
    language
}) => {
    function decodeHtml(string) {
        string = string.replace(/(<([^>]+)>)/gi, '');
        string = string.split(' ');
        var newStr = [];
        string.forEach((str, i) => {
            i < 18 ? newStr.push(str) : '';
        });
        newStr.push('...');
        string = newStr.join(' ');
        return string;
    }

    const onlyDate = new Date(date);

    const formatDate = (date) => {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        return [year, month, day].join('-');
    };

    const image = featuredImage
        ? {
              id: featuredImage.node.id,
              mediaItemId: featuredImage.node.id,
              mediaItemUrl: featuredImage.node.mediaItemUrl,
              title: featuredImage.node.title
          }
        : {};

    const category = categories?.nodes?.length
        ? categories.nodes[0]
        : { name: '', slug: '#' };

    return (
        <>
            <div
                className="post-inner"
                style={{ opacity: 1, marginBottom: '30px' }}
            >
                <div className="cdb-post-thumbnail">
                    <NextNavLink href={uri}>
                        <a rel="bookmark" title={title}>
                            {image.mediaItemUrl ? (
                                <img
                                    width="600"
                                    height="302"
                                    src={image.mediaItemUrl}
                                    alt={image.title}
                                    className="attachment-codebean-blog-masonry size-codebean-blog-masonry wp-post-image"
                                />
                            ) : (
                                ''
                            )}
                        </a>
                    </NextNavLink>
                </div>
                <div className="cdb-blog-content">
                    <div className="cdb-post-meta">
                        <span className="cdb-post-meta-date">
                            {formatDate(onlyDate)}
                        </span>
                        <span className="cdb-post-meta-category">
                            <NextNavLink href={`/category/${category.slug}`}>
                                <a
                                    aria-label={category.name || `category`}
                                    title={category.name}
                                >
                                    {category.name}
                                </a>
                            </NextNavLink>
                        </span>
                    </div>
                    <h4 className="cdb-post-title">
                        <NextNavLink href={uri}>
                            <a title={title}>{title}</a>
                        </NextNavLink>
                    </h4>
                    <div className="cdb-post-excerpt">
                        <div
                            dangerouslySetInnerHTML={{
                                __html: decodeHtml(excerpt)
                            }}
                        ></div>
                        <p className="read-more-section">
                            <NextNavLink href={uri}>
                                <a className="read-more" title={title}>
                                    {language === 'en' 
                                    ? 'Continue reading' 
                                    : 'Lire plus'}
                                </a>
                            </NextNavLink>
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default BlogPostThumbnail;
