import React from 'react';
import Head from 'next/head';

import Header from '../components/Header';
import Footer from '../components/Footer';
import LayoutContainer from '../components/LayoutContainer';

const PageLayout = ({ pageProps = {}, children, ...props }) => {
    const pageStyles = pageProps.bakeryBuilderCSS ? (
        <style type="text/css" dangerouslySetInnerHTML={{__html: pageProps.bakeryBuilderCSS}} />
    ) : null;

    const pageScripts = pageProps?.perPageFields?.customJavascript && pageProps.perPageFields.customJavascript.length ? (
        <script type="text/javascript" dangerouslySetInnerHTML={{ __html: pageProps.perPageFields.customJavascript }} />
    ) : null; 

    return (
        <LayoutContainer language={pageProps.language}>
            <Header {...pageProps} />
            <div className="site-content page-layout" id="content">
                <div id="primary" className="content-area vc_page_container">
                    <article>
                        <div className="entry-content">{children}</div>
                    </article>
                </div>
            </div>
            <Footer {...pageProps} />
            {pageStyles}
            {pageScripts}
        </LayoutContainer>
    );
};

export default PageLayout;
