import React from 'react';
import base64 from 'base-64';

import VisualComposerRawHTML from '../components/visual-composer/VisualComposerTextBlock';

function VisualComposerRawHTMLShortcode({ content }) {
    const decoded = decodeURIComponent(base64.decode(content));

    return (
        <VisualComposerRawHTML content={decoded} />
    );
}

export default VisualComposerRawHTMLShortcode;