import React, { useState, useEffect } from 'react';
import Head from 'next/head';
import { decodeHTMLEntities } from '../../util/miscellaneous';

function CBSectionTitle(originalProps) {
    let props = {
        ...originalProps
    };
    props = props.attr;

    const [title, setTitle] = useState(null);

    useEffect(() => {
        setTitle(decodeHTMLEntities(props.title));
    }, []);

    return (
        <>
            <div
                className={`cdb-section-title-holder cdb-section-title-${
                    props.title_text_align ? props.title_text_align : ''
                } ${props.el_class ? props.el_class : ''}`}
            >
                <h3
                    style={{
                        color: props.title_color ? props.title_color : '',
                        textAlign: props.title_text_align
                            ? props.title_text_align
                            : '',
                        marginBottom: props.margin_bottom
                            ? `${props.margin_bottom}px`
                            : '',
                        width: props.width ? `${props.width}%` : ''
                    }}
                    className={`cdb-section-title  ${
                        props.title_size ? props.title_size : ''
                    }`}
                >
                    {title}
                </h3>
            </div>
        </>
    );
}

export default CBSectionTitle;
