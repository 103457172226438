import React from 'react';
import Head from 'next/head';

function VisualComposerIcon({
    el_class,
    css,
    el_id,
    align = 'left',
    color = 'sky',
    background_style = '',
    background_color = '',
    size = 'md',
    icon
}) {
    return (
        <>
            {css ? (
                <Head>
                    <style type="text/css">{`
                    ${css && css}
                `}</style>
                </Head>
            ) : (
                ''
            )}
            <div
                id={el_id}
                className={`vc_icon_element vc_icon_element-outer vc_icon_element-align-${align} ${
                    background_color ? 'vc_icon_element-have-style' : ''
                } ${css ? css.split('{')[0].substring(1) : ''} ${
                    el_class ? el_class : ''
                }`}
            >
                <div
                    className={`vc_icon_element-inner  vc_icon_element-color-${color} vc_icon_element-size-${size} vc_icon_element-style-${background_style} ${
                        background_style === 'boxed-outline' ||
                        background_style === 'rounded-less-outline'
                            ? 'vc_icon_element-outline'
                            : 'vc_icon_element-background'
                    } vc_icon_element-background-color-${background_color} ${
                        background_color
                            ? 'vc_icon_element-have-style-inner'
                            : ''
                    }`}
                >
                    <span className={`vc_icon_element-icon ${icon}`}></span>
                </div>
            </div>
        </>
    );
}

export default VisualComposerIcon;
