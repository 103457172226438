import React from 'react';
import Head from 'next/head';
import TSHomeSlider from './TSHomeSlider';
function VisualComposerSection(originalProps) {
    let props = {
        ...originalProps
    };

    let attributes = [];
    if (
        props.attr.full_width == 'stretch_row' ||
        props.attr.full_width == 'stretch_row_content'
    ) {
        attributes['data-vc-full-width'] = true;
        attributes['data-vc-full-width-init'] = true;
    }
    if (props.attr.full_width === 'stretch_row_content') {
        attributes['data-vc-stretch-content'] = true;
    }
    delete attributes.pageProps;
    const isSlider = props.attr.is_slider;

    if (isSlider) {
        return (
            <TSHomeSlider
                arrows={props.attr.slider_arrows}
                dots={props.attr.slider_dots}
                autoplay={props.attr.slider_autoplay}
                autoplayDelay={props.attr.slider_autoplay_delay}
                loop={props.attr.slider_loop}
                keyboard={props.attr.slider_keyboard}
                mousewheel={props.attr.slider_mousewheel}
                effect={props.attr.slider_effect}
                id={props.attr.el_id}
                children={props.children}
            />
        );
    } else {
        return (
            <>
                {props.attr.css ? (
                    <Head>
                        <style type="text/css">{`
                    ${props.attr.css && props.attr.css}
                `}</style>
                    </Head>
                ) : (
                    ''
                )}
                {!props.attr.disable_element && (
                    <section
                        id={props.attr.el_id && props.attr.el_id}
                        {...attributes}
                        className={`vc_section ${
                            props.attr.full_height && props.attr.full_height
                                ? 'vc_row-o-full-height'
                                : ''
                        } ${
                            props.attr.content_placement &&
                            props.attr.content_placement
                                ? `vc_section-flex vc_section-o-content-${props.attr.content_placement}`
                                : ''
                        } ${props.attr.el_class ? props.attr.el_class : ''} ${
                            props.attr.css
                                ? props.attr.css.split('{')[0].substring(1)
                                : ''
                        } ${
                            props.attr.css
                                ? props.attr.css.search('background') != -1 ||
                                  props.attr.css.search('border') != -1
                                    ? 'vc_section-has-fill'
                                    : ''
                                : ''
                        }`}
                        style={
                            props.attr.full_width == 'stretch_row' ||
                            props.attr.full_width == 'stretch_row_content'
                                ? {
                                      position: 'relative',
                                      boxSizing: 'border-box',
                                      left: '15px',
                                      width: '100%',
                                      paddingLeft: '0',
                                      paddingRight: '0'
                                  }
                                : {}
                        }
                    >
                        {props.children}
                    </section>
                )}
            </>
        );
    }
}

export default VisualComposerSection;
