import React from 'react'

import { ARCHIVE_LAYOUTS } from '../../util/i18n';

const SearchHeading = ({resultsCount, searchTerms, ...props}) => {
    const resultDescription = props.language === 'en'
        ? 'We have found ' + resultsCount + ' results with the word you searched.'
        : 'Nous avons trouvé ' + resultsCount + ' résultats avec le mot que vous avez recherché.';

    return (
        <div className="container cdb-page-heading">
            <div className="row">
                <div className="col-sm-12">
                    <h2 className="cdb-page-title">
                        {resultsCount} {ARCHIVE_LAYOUTS['search_results'][props.language]} <span className="highlight">{searchTerms}</span>
                    </h2>
                    <div className="cdb-page-description">
                        <p>
                            {resultDescription}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SearchHeading
