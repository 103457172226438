import React from 'react';
import Head from 'next/head';
import CountUp from 'react-countup';
function CBAnimatedCounter({
    add_icon = false,
    size = 'medium',
    align = 'center',
    color_scheme = 'dark',
    value = 0,
    label,
    el_class,
    text_color = '#000',
    icon,
    icon_color,
    icon_size
}) {
    return (
        <>
            <div
                className={`cdb-counter cdb-counter-${size} text-${align} cdb-counter-${color_scheme} ${
                    el_class ? el_class : ''
                }`}
            >
                {add_icon && (
                    <div className="cdb-counter-icon-wrapper">
                        <i
                            className={`cdb-counter-icon ${icon}`}
                            style={{
                                color: icon_color ? icon_color : '',
                                fontSize: icon_size ? icon_size : ''
                            }}
                        ></i>
                    </div>
                )}
                <div
                    className="cdb-counter-text"
                    style={{ opacity: 1, color: text_color }}
                >
                    <div className="cdb-counter-number">
                        <CountUp
                            duration={1.75}
                            end={value ? parseFloat(value) : 0}
                        />
                    </div>
                    <div className="cdb-counter-label">{label}</div>
                </div>
            </div>
        </>
    );
}
export default CBAnimatedCounter;
