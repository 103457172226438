import React from 'react';

const VisualComposerSeparator = ({
    align = 'center',
    borderWidth = 1,
    color = 'grey',
    className = '',
    idName = '',
    style = 'border',
    elWidth = 100,
    title = 'Title',
    titleAlign = 'separator_align_center'
}) => {
    return (
        <>
            <div
                id={idName}
                className={`vc_separator wpb_content_element vc_separator_has_text
                ${`vc_${titleAlign}`}
                ${`vc_sep_pos_align_${align}`}
                ${`vc_sep_width_${elWidth}`}
                ${`vc_sep_color_${color}`}
                ${`vc_sep_border_width_${borderWidth}`}
                ${`vc_sep_${style}`}
                ${className}
            `}
            >
                <span className="vc_sep_holder vc_sep_holder_l">
                    <span className="vc_sep_line"></span>
                </span>
                <h4>{title}</h4>
                <span className="vc_sep_holder vc_sep_holder_r">
                    <span className="vc_sep_line"></span>
                </span>
            </div>
        </>
    );
};

export default VisualComposerSeparator;
