import React, { useState, useEffect } from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';

import Header from '../components/Header';
import Footer from '../components/Footer';
import NextNavLink from '../components/NextNavLink';
import LayoutContainer from '../components/LayoutContainer';
import SearchHeading from './../components/Search/SearchHeading';
import SearchContainer from './../components/Search/SearchContainer';

import { ARCHIVE_LAYOUTS } from '../util/i18n';

const SearchLayout = ({ pageProps = {}, children }) => {
    const pageStyles = pageProps.bakeryBuilderCSS ? (
        <style type="text/css" dangerouslySetInnerHTML={{__html: pageProps.bakeryBuilderCSS}} />
    ) : null;

    // --------------------------------------
    // States
    // --------------------------------------
    const [searchQuery, setSearchQuery] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [language, setLanguage] = useState('en');
    const [loading, setLoading] = useState(true);

    const [results, setResults] = useState([]);
    const [paginationInfo, setPaginationInfo] = useState({});

    //Effects
    // --------------------------------------

    useEffect(() => {
        (async function() {
            setLoading(true);
            const resp = await fetch(
                `/api/search?q=${searchQuery}&page=${pageNumber}&lang=${language}`
            );
            const json = await resp.json();

            setResults(json.results?.results || []);

            // ----------------
            // previous elements before to set the paginationInfo
            let remainingPaginationInfo =
                json.results?.pageInfo?.offsetPagination || {};
            let currentPage;

            if (pageNumber) {
                currentPage = parseInt(pageNumber);
            } else {
                currentPage = 1;
            }

            setPaginationInfo({
                ...paginationInfo,
                currentPage,
                ...remainingPaginationInfo
            });
            setLoading(false);
        })();
    }, [searchQuery, pageNumber, language]);

    useEffect(() => {
        if(typeof window !== 'undefined') {
            const urlParams = new URLSearchParams(window.location.search);
            const q = urlParams.get('q') || '';
            const lang = urlParams.get('lang') || 'en';
            const page = urlParams.get('p') || 1;

            setSearchQuery(q);
            setLanguage(lang);
            setPageNumber(page);
        }
    }, []);

    // --------------------------------------
    // Handlers
    // --------------------------------------

    // Pagination Link Handler
    const getPaginationLink = (number, type) => ({ children }) => {
        /*
        */
        const href = `/search/?q=${searchQuery}&p=${number}&lang=${language}`;

        switch(type) {
            case 'next':
                return (
                    <a
                        href="#"
                        onClick={() => {
                            setResults([]);
                            setPageNumber(number);
                        }}
                        aria-label="pagination"
                        className="next page-numbers"
                    >
                        <i className="fas fa-angle-right"></i>
                    </a>
                );
                break;

            case 'prev':
                return (
                    <a
                        href="#"
                        onClick={() => {
                            setResults([]);
                            setPageNumber(number);
                        }}  
                        aria-label="pagination"
                        className="prev page-numbers"
                    >
                        <i className="fas fa-angle-left"></i>
                    </a>
                );
                break;
        }

        return (
            <a
                href="#"
                onClick={() => {
                    setResults([]);
                    setPageNumber(number);
                }}
                aria-label="pagination"
                className="page-numbers"
            >
                {number}
            </a>
        );
    };

    return (
        <LayoutContainer language={pageProps.language}>
            <Header {...pageProps} />
            {
            !loading && results.length > 0 ? <SearchHeading
                resultsCount={results && paginationInfo?.total}
                searchTerms={searchQuery && searchQuery}
                language={pageProps.language}
            /> : null
            }
            {results && results.length > 0 ? (
                <SearchContainer
                    results={results}
                    paginationInfo={paginationInfo}
                    paginationHandler={getPaginationLink}
                    language={pageProps.language}
                />
            ) : (
                    loading === true ? (
                        <div className="text-center my-4 text-muted py-4 search-loader">
                            <div className="ts-loader" role="status"></div>
                            {ARCHIVE_LAYOUTS['loading'][pageProps.language]}
                        </div>
                    ) : (
                        <div className="text-center my-4 text-muted py-4 search-loader">
                            {ARCHIVE_LAYOUTS['search_no_results'][pageProps.language]}
                        </div>
                    )
            )}
            <Footer {...pageProps} />
            {pageStyles}
        </LayoutContainer>
    );
};

export default SearchLayout;
