import React from 'react';
import CodebeanTestimonialsItem from './../components/visual-composer/CodebeanTestimonialsItem';

function CodebeanTestimonialsItemShortcode({ content, ...props }) {
    const {
        name,
        el_class,
        style,
        user_role,
        image,
        img_size,
        pageProps,
        meta_properties
    } = props;

    return (
        <CodebeanTestimonialsItem
            name={name}
            userRole={user_role}
            imageId={image}
            imageSize={img_size}
            pageProps={pageProps}
            className={el_class}
            style={style}
            content={content}
            meta_properties={meta_properties}
        />
    );
}

export default CodebeanTestimonialsItemShortcode;
