import React from 'react';
import Head from 'next/head';

function VisualComposerRowInner(originalProps) {
    let props = {
        ...originalProps
    };
    //  let attributes = { ...originalProps.attr };
    let attributes = [];
    if (
        props.attr.full_width === 'stretch_row' ||
        props.attr.full_width === 'stretch_row_content'
    ) {
        attributes['data-vc-full-width'] = true;
        attributes['data-vc-full-width-init'] = true;
    }
    if (props.attr.full_width === 'stretch_row_content') {
        attributes['data-vc-stretch-content'] = true;
    }
    delete attributes.pageProps;
    return (
        <>
            {props.attr.css ? (
                <Head>
                    <style type="text/css">{`
                    ${props.attr.css && props.attr.css}
                `}</style>
                </Head>
            ) : (
                ''
            )}
            <div
                className={`cdb-grid-section cdb-grid-container  ${
                    props.attr.css
                        ? props.attr.css.split('{')[0].substring(1)
                        : ''
                } ${props.attr.gap ? `col-space-${props.attr.gap}` : ''}`}
            >
                <div
                    id={props.attr.el_id && props.attr.el_id}
                    {...attributes}
                    className={`vc_row wpb_row vc_inner vc_row-fluid ${
                        props.attr.css
                            ? props.attr.css.split('{')[0].substring(1)
                            : ''
                    } ${props.attr.el_class ? props.attr.el_class : ''} ${
                        props.attr.css
                            ? props.attr.css.search('background') != -1 ||
                              props.attr.css.search('border') != -1
                                ? 'vc_row-has-fill'
                                : ''
                            : ''
                    } ${
                        props.attr.gap ? `vc_column-gap-${props.attr.gap}` : ''
                    } ${
                        props.attr.content_placement
                            ? props.attr.content_placement
                                ? `vc_row-flex vc_row-o-content-${props.attr.content_placement}`
                                : ''
                            : ''
                    } ${
                        props.attr.equal_height
                            ? props.attr.equal_height
                                ? ` vc_row-flex vc_row-o-equal-height`
                                : ''
                            : ''
                    }`}
                >
                    {props.children}
                </div>
            </div>
        </>
    );
}

export default VisualComposerRowInner;
