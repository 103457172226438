import React, { useState, useEffect } from 'react';

// utils
import { stringToArray } from '../../util/miscellaneous';

const CBPricingTable = ({
    showIcon = false,
    iconStyle = '',
    icon = 'ti-wand',
    customIcon,
    title = 'Basic Plan',
    subtitle = '',
    price = '',
    currency = '',
    pricePeriod = '',
    description = '',
    priceColor = '',
    buttonLink = '',
    buttonText = '',
    className = '',
    content,
    meta_properties
}) => {
    /* Inner Content logic */

    const priceDescription = `<div class="cdb-price-description"> <p>${description}</p> </div>`; //this is the final element to inject inside the inner content

    const rawHtmlContent = `${priceDescription} ${content}`; //if there is description concatenate both elements that goes inside the innerContent.

    /* End of Inner Content logic */
    const image = meta_properties.url
        ? {
              id: meta_properties.id,
              mediaItemId: meta_properties.id,
              mediaItemUrl: meta_properties.url,
              title: meta_properties.title,
              sizes: null,
              srcSet: null,
              caption: meta_properties.caption
          }
        : {};

    /* Button Logic */

    const [buttonObject, setButtonObject] = useState({});

    useEffect(() => {
        const buttonArray = stringToArray(buttonLink, '|');
        debugger;
        // Split the Button Array and inject the properties in a state to print them after more easily on the Button markup
        if (buttonArray.length > 1) {
            const url = unescape(buttonArray[0].split(':')[1].substring(0));
            const title = unescape(buttonArray[1].split(':')[1].substring(0));
            const target = buttonArray[2].split(':')[1].substring(0);
            setButtonObject({ ...buttonObject, url, title, target });
        }
    }, []);

    /* End of Button Logic */

    return (
        <div className={`cdb-pricing-table ${className}`}>
            <div
                className="cdb-pricing-top"
                style={{ backgroundColor: priceColor }}
            >
                {showIcon ? (
                    <div className="price-icon">
                        {iconStyle === 'image' ? (
                            <div className="price-icon-image">
                                {image.mediaItemUrl ? (
                                    <img
                                        width="100"
                                        height="100"
                                        src={image.mediaItemUrl}
                                        className="attachment-full size-full"
                                        alt=""
                                    />
                                ) : (
                                    ''
                                )}
                            </div>
                        ) : (
                            <div className="price-icon-element">
                                <div
                                    className={`price-icon-item ${icon}`}
                                ></div>
                            </div>
                        )}
                    </div>
                ) : (
                    ''
                )}
                <h3 className="cdb-pricing-title">{title}</h3>
                <span className="cdb-pricing-subtitle">{subtitle}</span>
            </div>
            <div className="cdb-pricing-content">
                {price.length > 0 ? (
                    <div
                        className="cdb-pricing-price"
                        style={{ color: priceColor }}
                    >
                        <span className="currency">{currency}</span>
                        <span className="price-number">{price}</span>
                        <span className="price-time">{pricePeriod}</span>
                    </div>
                ) : (
                    ''
                )}

                <div
                    className="cdb-pricing-content-inner"
                    dangerouslySetInnerHTML={{ __html: rawHtmlContent }}
                ></div>

                {buttonLink.length > 0 ? (
                    <div className="cdb-price-button">
                        <a
                            href={buttonObject.url}
                            className="btn btn-bordered btn-light btn-medium"
                            target={buttonObject.target}
                            title={buttonObject.title}
                        >
                            <span>{buttonText}</span>
                        </a>
                    </div>
                ) : (
                    ''
                )}
            </div>
        </div>
    );
};

export default CBPricingTable;
