import React from 'react';

import CBIconList from '../components/visual-composer/CBIconList';

function CBIconListShortcode(props) {
    const {
        icon,
        icon_color,
        icon_size,
        margin_bottom,
        space_title_and_icon,
        title,
        title_color,
        title_font_weight,
        title_size,
        subtitle,
        link,
        el_class
    } = props;
    return (
        <CBIconList
            icon={icon}
            icon_color={icon_color}
            icon_size={icon_size}
            margin_bottom={margin_bottom}
            space_title_and_icon={space_title_and_icon}
            title={title}
            title_color={title_color}
            title_font_weight={title_font_weight}
            title_size={title_size}
            subtitle={subtitle}
            subtitle={subtitle}
            el_class={el_class}
            link={link}
        />
    );
}

export default CBIconListShortcode;
