import React from 'react';

import NextNavLink from '../NextNavLink';
import FooterMenu from './FooterMenu';
import SubFooter from './SubFooter';

export default function Footer(props) {
    const lang = props.locale.locale;
    const language = props.language;
    const { options, menus, template } = props;

    return (
        <footer className="cdb-footer dark">
            {template.templateName === 'Default' ? (
                <FooterMenu language={language} lang={lang} options={options} menus={menus} />
            ) : (
                ''
            )}
            <SubFooter lang={lang} options={options} />
        </footer>
    );
}
