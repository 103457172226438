import React from 'react';

import VisualComposerSeparator from './../components/visual-composer/VisualComposerSeparator';

const VisualComposerSeparatorShortcode = (props) => {
    const {
        align,
        border_width,
        color,
        el_class,
        el_id,
        el_width,
        style
    } = props;

    return (
        <VisualComposerSeparator
            align={align}
            borderWidth={border_width}
            color={color}
            className={el_class}
            idName={el_id}
            style={style}
            elWidth={el_width}
        />
    );
};

export default VisualComposerSeparatorShortcode;
