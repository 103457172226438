import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const VisualComposerTabsOption = ({
    className = '',
    id,
    children,
    active,
    ...props
}) => {
    return (
        <div
            id={id}
            className={`vc_tta-panel ${active ? 'vc_active' : ''} ${className}`}
        >
            <motion.div
                className="vc_tta-panel-body"
                initial="collapsed"
                animate={active ? 'open' : 'collapsed'}
                exit="collapsed"
                variants={{
                    open: { height: 'auto', paddingBottom: 14, paddingTop: 14 },
                    collapsed: {
                        height: 0,
                        paddingBottom: 0,
                        paddingTop: 0,
                        overflow: 'hidden'
                    }
                }}
                transition={{ duration: 0.25 }}
            >
                {children}
            </motion.div>
        </div>
    );
};

export default VisualComposerTabsOption;
