/* Please use this file just for pure functional utilities */

/* Mozilla recommended mechanism for testing localStorage availability */
function storageAvailable() {
    try {
        var storage = window['localStorage'],
            x = '__storage_test__';
        storage.setItem(x, x);
        storage.removeItem(x);
        return true;
    } catch (e) {
        return false;
    }
}

/* WYSIWYG utility,
This allow us to wrap each line inside a <p> tag */
const splitLines = (string) => {
    let splitString = string.split('\n');

    let returnedString = [];

    splitString.forEach((line) => {
        returnedString.push(`<p>${line}</p>`); //wrap each Line inside a P
    });

    return returnedString.join('');
};

/* Split String into an Array based in a delimiter character */
const stringToArray = (string, delimiter) => {
    let splitString = string.split(delimiter);

    return splitString;
};

/*Decode HTML Entities to text */
const decodeHTMLEntities = (string) => {
    let textArea = document.createElement('textarea');
    textArea.innerHTML = string;
    return textArea.value;
};

export { 
    splitLines, 
    stringToArray,
    storageAvailable,
    decodeHTMLEntities 
};
