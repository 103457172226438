import React from 'react';

import VisualComposerIcon from '../components/visual-composer/VisualComposerIcon';

function VisualComposerIconShortCode(props) {
    const {
        el_class,
        color,
        css,
        el_id,
        align,
        background_style,
        background_color,
        size,
        icon_typicons,
        icon_themify,
        icon_fontawesome,
        icon_monosocial,
        icon_openiconic,
        icon_entypo,
        icon_linecons,
        icon_material
    } = props;
    return (
        <VisualComposerIcon
            el_class={el_class}
            el_id={el_id}
            color={color}
            css={css}
            align={align}
            background_style={background_style}
            background_color={background_color}
            size={size}
            icon={
                icon_typicons ||
                icon_fontawesome ||
                icon_themify ||
                icon_openiconic ||
                icon_entypo ||
                icon_linecons ||
                icon_monosocial ||
                icon_material
            }
        />
    );
}

export default VisualComposerIconShortCode;
