import React from 'react';

import VisualComposerColumn from '../components/visual-composer/VisualComposerColumn';

function VisualComposerColumnShortcode(props) {
    return (
        <VisualComposerColumn attr={props}>
            {props.children}
        </VisualComposerColumn>
    );
}

export default VisualComposerColumnShortcode;
