import React from 'react';
import CookieBar from '../CookieBar';

export default function LayoutContainer({ children, language }) {
    return (
        <div className="layout">
            {children}
            
            <CookieBar language={language} />
        </div>
    );
}
