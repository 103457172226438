import React from 'react';
import Head from 'next/head';

function VisualComposerTextBlock(originalProps) {
    let props = {
        ...originalProps
    };
    /*
     * Visual Composer text blocks include raw HTML including comments,
     * so render inline (for now!)
     */

    const content =
        originalProps.attr?.meta_properties?.transformed_text ||
        originalProps.attr?.content;

    return (
        <>
            {props.attr?.css ? (
                <Head>
                    <style type="text/css">{`
                    ${props.attr?.css && props.attr.css}
                `}</style>
                </Head>
            ) : (
                ''
            )}

            <div
                id={props.attr?.el_id && props.attr.el_id}
                className={`wpb_text_column wpb_content_element ${
                    props.attr?.el_class ? props.attr.el_class : ''
                }  ${props.attr.el_class ? props.attr.el_class : ''} ${
                    props.attr.css
                        ? props.attr.css.split('{')[0].substring(1)
                        : ''
                } `}
            >
                <div
                    className="wpb_wrapper"
                    dangerouslySetInnerHTML={{
                        __html: content
                    }}
                />
            </div>
        </>
    );
}

export default VisualComposerTextBlock;
