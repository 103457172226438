import React, { useRef, useState } from 'react';
import { Transition } from '@headlessui/react';

import { makeLinkRelative } from '../../util/wordpress';
import { LOCALE_NAME_FROM_LOCALE_CODE } from '../../util/i18n.js';
import NextNavLink from '../NextNavLink';

import { useClickOutside } from '../../util/hooks';
import BlogSearchWidget from '../Blog/BlogSearchWidget';

/* A single, simple Tailwind UI dropdown menu */
export function DropdownMenu({ menuItem, leftCol, language, mobile, hideMobileMenu }) {
    const ref = useRef();
    const parentRef = useRef();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    let leftColData;
    if (language === 'en') {
        leftColData = leftCol.commonMenuContentEn;
    } else if (language === 'fr') {
        leftColData = leftCol.commonMenuContentFr;
    } else {
        leftColData = leftCol.commonMenuContentFrFr;
    }

    useClickOutside(ref, () => setTimeout(() => setDropdownOpen(false)));

    return (
        <div
            className="position-relative"
            onMouseOver={() => !mobile && setDropdownOpen(true)}
            onMouseLeave={() => !mobile && setDropdownOpen(false)}
        >
            <NextNavLink href="#">
                <a
                    onClick={() => {
                        mobile && setDropdownOpen(!dropdownOpen);
                    }}
                    ref={parentRef}
                    className="nav-link"
                    aria-label={menuItem.title}
                >
                    <span>{menuItem.title}</span>
                    <svg
                        className=""
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                        width="20px"
                    >
                        <path
                            fillRule="evenodd"
                            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                            clipRule="evenodd"
                            fill="white"
                        />
                    </svg>
                </a>
            </NextNavLink>
            <Transition
                show={dropdownOpen}
                enter="transition-opacity"
                enterFrom="opacity-0 translate-y--1"
                enterTo="opacity-100 translate-y-0"
                leave="transition-opacity"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div ref={ref}>
                    <div
                        className={`mega-menu`}
                        style={{
                            width: `${
                                menuItem.children.length > 2
                                    ? 275 * menuItem.children.length + 240
                                    : 275 * menuItem.children.length
                            }px`
                        }}
                    >
                        <div className="vc_row wpb_row">
                            {menuItem.children.length > 2 ? (
                                <div className="vc_col-lg-3 text-center mega-menu-left-col">
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: leftColData
                                        }}
                                    />
                                </div>
                            ) : (
                                ''
                            )}
                            {menuItem.children.map((item, i) => (
                                <div
                                    key={i}
                                    className={`vc_col-lg-${
                                        menuItem.children.length !== 1
                                            ? menuItem.children.length % 12
                                            : 12
                                    }`}
                                >
                                    <span className="terranova-main-menu-subtitle">
                                        {item.title}
                                    </span>
                                    <ul>
                                        {item.children.map((child, ci) => (
                                            <li key={ci}>
                                                <NextNavLink
                                                    href={
                                                        child.url || child.path
                                                    }
                                                >
                                                    <a
                                                        onClick={() => {
                                                            setDropdownOpen(
                                                                !dropdownOpen
                                                            )

                                                            if(mobile) {
                                                                hideMobileMenu();
                                                            }
                                                        }}
                                                        aria-label={child.title}
                                                        className="megamenu-link"
                                                    >
                                                        {child.title}
                                                    </a>
                                                </NextNavLink>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </Transition>
        </div>
    );
}

/* An example Tailwind UI menu supporting sub-items as dropdowns */
function ExampleMenu({
    menuItems,
    logo,
    template,
    translations,
    options,
    language,
    locale: { locale },
    ctaButton
}) {
    let facebook = options?.socialLinks?.facebook;
    let linkedin = options?.socialLinks?.linkedin;
    let twitter = options?.socialLinks?.twitter;
    let youtube = options?.socialLinks?.youtube;

    const [showMobileMenu, setShowMobileMenu] = useState(false);
    const ref = useRef();
    const topNavData = options.topNavigation;
    const [searchOpen, setSearchOpen] = useState(false);
    useClickOutside(ref, () => setShowMobileMenu(false));

    return (
        <div className="container">
            <div className="row">
                <div className="col-sm-12 col-md-12">
                    <div className="cdb-regular-header">
                        <div className="cdb-logo-holder">
                            <NextNavLink href="/">
                                <a
                                    aria-label="Home"
                                    className="cdb-logo-link"
                                    title="Cyber Security Awareness"
                                >
                                    <img
                                        className=""
                                        src={logo}
                                        alt="logo"
                                        className="cdb-logo-image"
                                    />
                                </a>
                            </NextNavLink>
                        </div>

                        <nav className="nav-items">
                            {template !== 'Landing Page' ? (
                                <ul>
                                    {menuItems.map((menuItem, i) =>
                                        menuItem.children.length ? (
                                            <li key={i}>
                                                <DropdownMenu
                                                    menuItem={menuItem}
                                                    leftCol={topNavData}
                                                    language={language}
                                                />
                                            </li>
                                        ) : (
                                            <li key={i}>
                                                <NextNavLink
                                                    href={
                                                        menuItem.path ||
                                                        menuItem.url
                                                    }
                                                >
                                                    <a
                                                        aria-label={
                                                            menuItem.label
                                                        }
                                                        className="nav-link"
                                                    >
                                                        {menuItem.label}
                                                    </a>
                                                </NextNavLink>
                                            </li>
                                        )
                                    )}
                                    {ctaButton && (
                                        <li>
                                            <NextNavLink
                                                href={ctaButton.url}
                                                target={ctaButton.target}
                                            >
                                                <a
                                                    aria-label={ctaButton.title}
                                                    className="vc_btn3 vc_btn3-size-md vc_btn3-shape-round vc_btn3-style-flat vc_btn3-block vc_btn3-color-primary nav-link"
                                                >
                                                    {ctaButton.title}
                                                </a>
                                            </NextNavLink>
                                        </li>
                                    )}
                                </ul>
                            ) : (
                                <>
                                    <div className="wpml-ls-statics-shortcode_actions wpml-ls wpml-ls-legacy-list-horizontal">
                                        <ul>
                                            <li>
                                                {options?.topNavigation
                                                    ?.phoneNumber && (
                                                    <div className="widget widget_text cdb-top-bar-widget">
                                                        <div className="cdb-top-bar-widget-inner">
                                                            <div className="textwidget">
                                                                <div className="cdb-icon-list-item">
                                                                    <div className="cdb-list-icon-wrap">
                                                                        <i
                                                                            className="ti-mobile"
                                                                            style={{
                                                                                color:
                                                                                    '#000000'
                                                                            }}
                                                                        ></i>
                                                                    </div>
                                                                    <div className="cdb-list-icon-content">
                                                                        <span
                                                                            className="cdb-list-icon-title"
                                                                            style={{
                                                                                color:
                                                                                    '#000000'
                                                                            }}
                                                                        >
                                                                            {
                                                                                options
                                                                                    ?.topNavigation
                                                                                    ?.phoneNumber
                                                                            }
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </li>

                                            {translations.map(
                                                (locale, index) => (
                                                    <li
                                                        className="wpml-ls-slot-shortcode_actions wpml-ls-item wpml-ls-item-fr wpml-ls-first-item wpml-ls-item-legacy-list-horizontal"
                                                        key={index}
                                                    >
                                                        <NextNavLink
                                                            href={makeLinkRelative(
                                                                locale.href
                                                            )}
                                                        >
                                                            <a aria-label="language">
                                                                {
                                                                    LOCALE_NAME_FROM_LOCALE_CODE[
                                                                        locale
                                                                            .locale
                                                                    ][
                                                                        locale
                                                                            .locale
                                                                    ]
                                                                }
                                                            </a>
                                                        </NextNavLink>
                                                    </li>
                                                )
                                            )}
                                        </ul>
                                    </div>
                                </>
                            )}
                        </nav>
                        <div className="mobile-menu-icons">
                            <div className="search-btn ">
                                <div className="search-container">
                                    <a
                                        onClick={() => {
                                            setSearchOpen(!searchOpen);
                                        }}
                                    >
                                        <i
                                            className={`fa fa-fw ${
                                                searchOpen
                                                    ? 'fa-times'
                                                    : 'fa-search'
                                            } `}
                                        ></i>
                                    </a>
                                    <Transition
                                        show={searchOpen}
                                        enter="transition-opacity"
                                        enterFrom="opacity-0 translate-y--1"
                                        enterTo="opacity-100 translate-y-0"
                                        leave="transition-opacity"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                    >
                                        <BlogSearchWidget locale={locale} />
                                    </Transition>
                                </div>
                            </div>
                            <div
                                className="menu-icon"
                                onClick={() =>
                                    setShowMobileMenu(!showMobileMenu)
                                }
                            >
                                <img
                                    src="/images/bars-solid.svg"
                                    width="20px"
                                    alt=""
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Transition
                enter="transition-opacity"
                enterFrom="opacity-0 translate-y--1"
                enterTo="opacity-100 translate-y-0"
                leave="transition-opacity"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                show={showMobileMenu}
            >
                <>
                    <div ref={ref} className="mobile-menu">
                        {template !== 'Landing Page' ? (
                            <>
                                <ul>
                                    {menuItems.map((menuItem, i) =>
                                        menuItem.children.length ? (
                                            <li key={i}>
                                                <DropdownMenu
                                                    mobile={true}
                                                    key={i}
                                                    menuItem={menuItem}
                                                    leftCol={topNavData}
                                                    language={language}
                                                    hideMobileMenu={() => setShowMobileMenu(false)}
                                                />
                                            </li>
                                        ) : (
                                            <li key={i}>
                                                <NextNavLink
                                                    onClick={() =>
                                                        setShowMobileMenu(
                                                            !showMobileMenu
                                                        )
                                                    }
                                                    href={
                                                        menuItem.path ||
                                                        menuItem.url
                                                    }
                                                >
                                                    <a onClick={() => setShowMobileMenu(false)} className="nav-link">
                                                        {menuItem.label}
                                                    </a>
                                                </NextNavLink>
                                            </li>
                                        )
                                    )}

                                    {ctaButton && (
                                        <li>
                                            <NextNavLink
                                                href={ctaButton.url}
                                                target={ctaButton.target}
                                            >
                                                <a
                                                    onClick={() =>
                                                        setShowMobileMenu(
                                                            !showMobileMenu
                                                        )
                                                    }
                                                    className="vc_btn3 vc_btn3-size-md vc_btn3-style-flat vc_btn3-block vc_btn3-color-primary nav-link"
                                                >
                                                    {ctaButton.title}
                                                </a>
                                            </NextNavLink>
                                        </li>
                                    )}
                                </ul>
                            </>
                        ) : (
                            ''
                        )}
                        {options?.topNavigation?.localNumber && (
                            <div className="cdb-icon-list-item">
                                <div className="cdb-list-icon-wrap">
                                    <i
                                        className="ti-mobile"
                                        style={{
                                            color: '#000000',
                                            fontSize: '15px'
                                        }}
                                    ></i>
                                </div>
                                <div className="cdb-list-icon-content">
                                    <p>
                                        <span
                                            className="cdb-list-icon-title"
                                            style={{
                                                color: '#000000',
                                                fontSize: '12px'
                                            }}
                                        >
                                            Local:
                                            {
                                                options?.topNavigation
                                                    ?.localNumber
                                            }
                                        </span>
                                    </p>
                                </div>
                            </div>
                        )}
                        {options?.topNavigation?.phoneNumber && (
                            <div className="cdb-icon-list-item">
                                <div className="cdb-list-icon-wrap">
                                    <i
                                        className="ti-mobile"
                                        style={{
                                            color: '#000000',
                                            fontSize: '15px'
                                        }}
                                    ></i>
                                </div>
                                <div className="cdb-list-icon-content">
                                    <p>
                                        <span
                                            className="cdb-list-icon-title"
                                            style={{
                                                color: '#000000',
                                                fontSize: '12px'
                                            }}
                                        >
                                            Toll Free:
                                            {
                                                options?.topNavigation
                                                    ?.phoneNumber
                                            }
                                        </span>
                                    </p>
                                </div>
                            </div>
                        )}
                        {options?.topNavigation?.email && (
                            <div className="cdb-icon-list-item">
                                <div className="cdb-list-icon-wrap">
                                    <i
                                        className="ti-email"
                                        style={{
                                            color: '#000000',
                                            fontSize: '15px'
                                        }}
                                    ></i>
                                </div>
                                <div className="cdb-list-icon-content">
                                    <p>
                                        <span
                                            className="cdb-list-icon-title"
                                            style={{
                                                color: '#000000',
                                                fontSize: '12px'
                                            }}
                                        >
                                            {options?.topNavigation?.email}
                                        </span>
                                    </p>
                                </div>
                            </div>
                        )}
                        <ul className="cdb-social-links">
                            {facebook && (
                                <li>
                                    <a
                                        href={facebook}
                                        target="_blank"
                                        className="Facebook"
                                        title="Facebook"
                                        rel="noopener noreferrer"
                                    >
                                        <i className="fab fa-facebook-f"></i>
                                    </a>
                                </li>
                            )}
                            {linkedin && (
                                <li>
                                    <a
                                        href={linkedin}
                                        target="_blank"
                                        className="LinkedIn"
                                        title="LinkedIn"
                                        rel="noopener noreferrer"
                                    >
                                        <i className="fab fa-linkedin-in"></i>
                                    </a>
                                </li>
                            )}
                            {twitter && (
                                <li>
                                    <a
                                        href={twitter}
                                        target="_blank"
                                        className="Twitter"
                                        title="Twitter"
                                        rel="noopener noreferrer"
                                    >
                                        <i className="fab fa-twitter"></i>
                                    </a>
                                </li>
                            )}
                            {youtube && (
                                <li>
                                    <a
                                        href={youtube}
                                        target="_blank"
                                        className="YouTube"
                                        title="YouTube"
                                        rel="noopener noreferrer"
                                    >
                                        <i className="fab fa-youtube"></i>
                                    </a>
                                </li>
                            )}
                        </ul>
                        <div className="wpml-ls-statics-shortcode_actions wpml-ls wpml-ls-legacy-list-horizontal">
                            <ul>
                                {translations.map((locale, index) => (
                                    <li
                                        className="wpml-ls-slot-shortcode_actions wpml-ls-item wpml-ls-item-fr wpml-ls-first-item wpml-ls-item-legacy-list-horizontal"
                                        key={index}
                                    >
                                        <NextNavLink
                                            href={makeLinkRelative(locale.href)}
                                        >
                                            <a>
                                                {
                                                    LOCALE_NAME_FROM_LOCALE_CODE[
                                                        locale.locale
                                                    ][locale.locale]
                                                }
                                            </a>
                                        </NextNavLink>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    <div className="back-cover"></div>
                </>
            </Transition>
        </div>
    );
}

export default ExampleMenu;
