import React from 'react';

import VisualComposerImagesCarousel from '../components/visual-composer/VisualComposerImagesCarousel';

function VisualComposerImagesCarouselShortCode(props) {
    const images = props.meta_properties.images;
    const {
        el_class,
        css,
        el_id,
        autoplay,
        img_size,
        wrap,
        speed,
        hide_pagination_control,
        hide_prev_next_buttons,
        slides_per_view,
        title
    } = props;
    return (
        <VisualComposerImagesCarousel
            el_class={el_class}
            css={css}
            el_id={el_id}
            images={images}
            autoplay={autoplay}
            img_size={img_size}
            wrap={wrap}
            speed={speed}
            hide_pagination_control={hide_pagination_control}
            hide_prev_next_buttons={hide_prev_next_buttons}
            slides_per_view={slides_per_view}
            title={title}
        />
    );
}

export default VisualComposerImagesCarouselShortCode;
