import React from 'react';
import Head from 'next/head';

function CBTeamMember(originalProps) {
    let props = {
        ...originalProps
    };
    props = props.attr;
    const image = props.meta_properties.url
        ? {
              id: props.meta_properties.id,
              mediaItemId: props.meta_properties.id,
              mediaItemUrl: props.meta_properties.url,
              title: props.meta_properties.title,
              sizes: null,
              srcSet: null,
              caption: props.meta_properties.caption
          }
        : {};

    return (
        <>
            <div
                className={`cdb-team-member style-${
                    props.layout ? props.layout : 'default'
                } ${props.el_class ? props.el_class : ''}`}
            >
                <div className="cdb-team-member-img">
                    <div className="member-img-holder">
                        {image.mediaItemUrl ? (
                            <img
                                src={image.mediaItemUrl}
                                alt={props.team_name || image.title}
                            />
                        ) : (
                            ''
                        )}
                    </div>
                </div>
                <div className="cdb-team-member-content">
                    <div className="cdb-member-content-header">
                        <div className="cdb-member-title-wrapper">
                            {props.team_name && (
                                <h4 className="cdb-member-name">
                                    {props.team_name}
                                </h4>
                            )}
                            {props.team_position && (
                                <h6 className="cdb-member-position">
                                    {props.team_position}
                                </h6>
                            )}
                        </div>
                        <div className="cdb-member-social">
                            <div className="cdb-member-social-wrapper">
                                <ul
                                    className={`rounded cdb-social-links ${props.style}`}
                                >
                                    {props.google_plus && (
                                        <li>
                                            <a
                                                aria-label="google"
                                                className="google"
                                                target="_blank"
                                                href={props.google_plus}
                                            >
                                                <i className="fab fa-google-plus-g"></i>
                                            </a>
                                        </li>
                                    )}
                                    {props.linkedin && (
                                        <li>
                                            <a
                                                aria-label="linkedin"
                                                className="linkedin"
                                                target="_blank"
                                                href={props.linkedin}
                                            >
                                                <i className="fab fa-linkedin"></i>
                                            </a>
                                        </li>
                                    )}
                                    {props.skype && (
                                        <li>
                                            <a
                                                aria-label="skype"
                                                target="_blank"
                                                className="skype"
                                                href={props.skype}
                                            >
                                                <i className="fab fa-skype"></i>
                                            </a>
                                        </li>
                                    )}
                                    {props.instagram && (
                                        <li>
                                            <a
                                                aria-label="instagram"
                                                className="instagram"
                                                target="_blank"
                                                href={props.instagram}
                                            >
                                                <i className="fab fa-instagram"></i>
                                            </a>
                                        </li>
                                    )}
                                </ul>
                            </div>
                        </div>
                    </div>
                    {props.team_description && (
                        <div className="cdb-member-description">
                            {props.team_description}
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}
export default CBTeamMember;
