import React from 'react';
import CodebeanTestimonials from './../components/visual-composer/CodebeanTestimonials';

function CodebeanTestimonialsShortcode({ children, ...props }) {
    const {
        autoplay,
        borders,
        el_class,
        layout,
        align,
        columns,
        color_scheme,
        show_pagination_control,
        show_prev_next_buttons,
        slides_per_view,
        wrap,
        css
    } = props;

    return (
        <CodebeanTestimonials
            autoplay={autoplay}
            borders={borders}
            className={el_class}
            layout={layout}
            align={align}
            colorScheme={color_scheme}
            columns={columns}
            showPaginationControl={show_pagination_control}
            showPrevNextButtons={show_prev_next_buttons}
            slidesPerView={slides_per_view}
            wrap={wrap}
            css={css}
        >
            {children}
        </CodebeanTestimonials>
    );
}

export default CodebeanTestimonialsShortcode;
