import React from 'react';
import Head from 'next/head';

function CBIconList({
    icon,
    icon_color = '#000',
    icon_size = 28,
    margin_bottom,
    space_title_and_icon = 0,
    title,
    title_color = '#000',
    title_font_weight = 600,
    title_size = 20,
    subtitle,
    link,
    el_class
}) {
    return (
        <>
            <div
                className={`cdb-icon-list-item position-relative ${
                    el_class ? el_class : ''
                }`}
                style={{
                    marginBottom: margin_bottom ? margin_bottom + 'px' : ''
                }}
            >
                {link && <a href={link} className="stretched-link"></a>}
                <div className="cdb-list-icon-wrap">
                    <i
                        className={icon}
                        style={{
                            fontSize: icon_size + 'px',
                            color: icon_color
                        }}
                    ></i>
                </div>
                <div
                    className="cdb-list-icon-content"
                    style={{ paddingLeft: space_title_and_icon + 'px' }}
                >
                    <span
                        className="cdb-list-icon-title"
                        style={{
                            fontSize: title_size + 'px',
                            color: title_color,
                            fontWeight: title_font_weight
                        }}
                    >
                        {title}
                    </span>
                    {subtitle && (
                        <span className="cdb-list-icon-subtitle">
                            {subtitle}
                        </span>
                    )}
                </div>
            </div>
        </>
    );
}
export default CBIconList;
