import React from 'react';
import Head from 'next/head';

import Header from '../components/Header';
import Footer from '../components/Footer';
import LayoutContainer from '../components/LayoutContainer';
import BlogHeading from './../components/Blog/BlogHeading';
import BlogContainer from './../components/Blog/BlogContainer';

const BlogLayout = ({ pageProps = {}, children }) => {
    const pageStyles = pageProps.bakeryBuilderCSS ? (
        <style type="text/css" dangerouslySetInnerHTML={{__html: pageProps.bakeryBuilderCSS}} />
    ) : null;

    const slug = pageProps.categorySlug
        ? pageProps.categorySlug 
        : pageProps.tagSlug || null;

    /*
     * Please check the contents of pageProps.blogArchiveData and pageProps.posts
     */
    return (
        <LayoutContainer language={pageProps.language}>
            {children}

            <Header {...pageProps} />

            <BlogHeading
                title={pageProps.title}
                viewType={pageProps.viewType}
                language={pageProps.language}
            />

            <BlogContainer
                locale={pageProps.locale.locale}
                posts={pageProps.posts}
                blogArchiveData={pageProps.blogArchiveData}
                recentPosts={pageProps.firstFivePosts}
                paginationInfo={pageProps.pageInfo}
                viewType={pageProps.viewType}
                language={pageProps.language}
                slug={slug}
            />

            <Footer {...pageProps} />
            {pageStyles}
        </LayoutContainer>
    );
};

export default BlogLayout;
