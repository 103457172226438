import React, { useState, useEffect } from 'react';
import Header from '../components/Header';
import { NextSeo } from 'next-seo';
import Footer from '../components/Footer';
import NextNavLink from '../components/NextNavLink';
import LayoutContainer from '../components/LayoutContainer';

const NotFoundLayout = ({ pageProps = {}, children }) => {
    return (
        <LayoutContainer language={pageProps.language}>
            <NextSeo title="Page not found" noindex={true} nofollow={true} />
            <Header {...pageProps} />

            <div className="site-content page-layout" id="content">
                <div className="container page-404">
                    <div className="row">
                        <div className="col-md-12">
                            <h1 className="error-page-title">404</h1>
                            <h2 className="error-page-message">Page not found</h2>
                            <p>We are sorry, but the page you're looking for cannot be found.</p>
                            <NextNavLink href="/">
                                <a 
                                    className="btn btn-standard btn-default btn-icon-left">
                                        <i className="fas fa-home"></i>
                                        <span>Back to Home</span>
                                </a>
                            </NextNavLink>
                        </div>
                    </div>
                </div>
            </div>
            <Footer {...pageProps} />
        </LayoutContainer>
    );
};

export default NotFoundLayout;
