import React from 'react';
import VisualComposerTour from '../components/visual-composer/VisualComposerTour';

const VisualComposerTourShortcode = ({ children, ...props }) => {
    const {
        css,
        active_section,
        alignment,
        controls_size,
        el_class,
        color,
        el_id,
        gap,
        shape,
        spacing,
        style,
        tab_position,
        title
    } = props;

    return (
        <>
            <VisualComposerTour
                activeSection={active_section}
                alignment={alignment}
                controlsSize={controls_size}
                className={el_class}
                color={color}
                id={el_id}
                gap={gap}
                shape={shape}
                spacing={spacing}
                style={style}
                tabPosition={tab_position}
                title={title}
                css={css}
            >
                {children}
            </VisualComposerTour>
        </>
    );
};

export default VisualComposerTourShortcode;
