import React from 'react';

import CBCountDownTimer from '../components/visual-composer/CBCountDownTimer';

function CBCountDownTimerShortcode(props) {
    const { size, date, style, align, el_class } = props;
    return (
        <CBCountDownTimer
            size={size}
            date={date}
            style={style}
            align={align}
            el_class={el_class}
        />
    );
}

export default CBCountDownTimerShortcode;
