import { useState, useEffect } from 'react';

import NextNavLink from '../NextNavLink';
import { storageAvailable } from '../../util/miscellaneous';

const TRANSLATION_STRINGS = {
    'privacy1': {
        'en': 'Our website uses cookies to optimize your browsing experience and map website usability. If you agree to proceed using cookies, please click on the box located at the right-hand side of this message.',
        'fr': 'Notre site web utilise des cookies afin d’optimiser l’expérience de navigation, ainsi que le bon fonctionnement du site. Si vous autorisez cette fonctionnalité, veuillez cliquer sur le bouton OK, ci droit.',
        'fr-fr': 'Notre site web utilise des cookies afin d’optimiser l’expérience de navigation, ainsi que le bon fonctionnement du site. Si vous autorisez cette fonctionnalité, veuillez cliquer sur le bouton OK, ci droit.'
    },
    'privacy2': {
        'en': 'Otherwise, go to your browser settings to disable the functionality.',
        'fr': 'Autrement, vous pouvez gérer la configuration des cookies en accédant à l’onglet paramètres de votre navigateur.',
        'fr-fr': 'Autrement, vous pouvez gérer la configuration des cookies en accédant à l’onglet paramètres de votre navigateur.'
    },
    'policy': {
        'en': 'Read Terranova Security’s Privacy Policy to find out more.',
        'fr': 'Lisez notre Politique sur la protection des renseignements personnels.',
        'fr-fr': 'Lisez notre Politique sur la protection des renseignements personnels.'
    },
    'policyLink': {
        'en': '/privacy-policy',
        'fr': '/fr/confidentialite',
        'fr-fr': '/fr-fr/confidentialite'
    }
};

function CookieBar({ language }) {
    const [showingCookieBar, setShowingCookieBar] = useState(false);

    useEffect(() => {
        if(!storageAvailable()) {
            return;
        }

        const acceptedCookies = localStorage.getItem('acceptedCookies');

        if(!acceptedCookies) {
            setShowingCookieBar(true);
        } else {
            /* Trigger tracking scripts, pixels */
            if(window.INSERT_GTM && !window.GTM_INSERTED && typeof window !== 'undefined' && process.env.NODE_ENV === 'production' && false) {
                window.INSERT_GTM();
            }
        }
    }, []);

    function acceptCookiePolicy() {
        localStorage.setItem('acceptedCookies', true);
        setShowingCookieBar(false);

        if(window.INSERT_GTM && !window.GTM_INSERTED && typeof window !== 'undefined' && process.env.NODE_ENV === 'production' && false) {
            window.INSERT_GTM();
        }
    }

    if(!showingCookieBar) {
        return null;
    }

    return (
        <div id="cookie-notice" role="banner" className="cookie-revoke-hidden cn-position-bottom cn-effect-fade cookie-notice-visible" aria-label="Cookie Notice" style={{ backgroundColor: `rgba(26, 26, 26, 1)` }}>
            <div className="cookie-notice-container" style={{ color: `#dddddd` }}>
                <span id="cn-notice-text" className="cn-text-container">
                    <p>{TRANSLATION_STRINGS['privacy1'][language]}</p>
                    <p>{TRANSLATION_STRINGS['privacy2'][language]}</p>
                    <NextNavLink href={TRANSLATION_STRINGS['policyLink'][language]}>
                        <a>{TRANSLATION_STRINGS['policy'][language]}</a>
                    </NextNavLink>
                </span>
                <span id="cn-notice-buttons" className="cn-buttons-container">
                    <a 
                        onClick={(ev) => {
                            ev.preventDefault();
                            acceptCookiePolicy();
                            return false;
                        }}
                        id="cn-accept-cookie" data-cookie-set="accept" className="cn-set-cookie cn-button btn btn-standard btn-default btn-small" aria-label="Ok">
                        OK
                    </a>
                </span>
                <div
                    onClick={(ev) => {
                        ev.preventDefault();
                        setShowingCookieBar(false);
                        return false;
                    }} 
                    id="cn-close-notice"
                    className="cn-close-icon" 
                    aria-label="Ok">
                </div>
            </div>
        </div>
    );
}

export default CookieBar;