import React from 'react';
import Head from 'next/head';

import Header from '../components/Header';
import Footer from '../components/Footer';
import LayoutContainer from '../components/LayoutContainer';
import PostContainer from './../components/Post/PostContainer';

const PostLayout = ({ pageProps = {}, children }) => {
    const pageStyles = pageProps.bakeryBuilderCSS ? (
        <style type="text/css" dangerouslySetInnerHTML={{__html: pageProps.bakeryBuilderCSS}} />
    ) : null;

    const firstCategory = pageProps.firstCategory.nodes[0];

    return (
        <LayoutContainer language={pageProps.language}>
            {children}
            <Header {...pageProps} />

            <PostContainer
                locale={pageProps.locale.locale}
                postImage={pageProps.featuredImage}
                postCategory={firstCategory}
                postDate={pageProps.date}
                postTitle={pageProps.title}
                postContent={pageProps.content}
                postUri={pageProps.uri}
                postOg={pageProps.seo.opengraphImage?.mediaItemUrl}
                nextPost={pageProps.next}
                previousPost={pageProps.previous}
                blogArchiveData={pageProps.blogArchiveData}
                recentPosts={pageProps.firstFivePosts}
            />

            <Footer {...pageProps} />
            {pageStyles}
        </LayoutContainer>
    );
};

export default PostLayout;
