import React from 'react';

import { splitLines } from './../../util/miscellaneous';

const CodebeanTestimonialsItem = ({
    name = '',
    userRole = '',
    imageId,
    imageSize,
    content,
    className = '',
    pageProps,
    meta_properties
}) => {
    const image = meta_properties.url
        ? {
              id: meta_properties.id,
              mediaItemId: meta_properties.id,
              mediaItemUrl: meta_properties.url,
              title: meta_properties.title,
              sizes: null,
              srcSet: null,
              caption: meta_properties.caption
          }
        : {};

    const splitContent = splitLines(content);

    return (
        <div className={`cdb-testimonial-item-holder ${className}`}>
            <div className="cdb-testimonial-item">
                <div className="cdb-testimonial-content">
                    <blockquote
                        dangerouslySetInnerHTML={{
                            __html: splitContent
                        }}
                    />
                </div>
                <div className="cdb-testimonial-user">
                    {image.mediaItemUrl ? (
                        <div className="cdb-testimonial-user-avatar">
                            <img
                                width="100"
                                height="100"
                                className={`testimonial-avatar-image attachment-${imageSize}`}
                                src={image.mediaItemUrl}
                                alt={name}
                            />
                        </div>
                    ) : (
                        ''
                    )}
                    {name.length > 0 || userRole.length > 0 ? (
                        <div className="cdb-testimonial-user-name">
                            {name.length > 0 ? (
                                <h5
                                    className="cdb-testimonial-author-name"
                                    dangerouslySetInnerHTML={{
                                        __html: name
                                    }}
                                />
                            ) : (
                                ''
                            )}
                            {userRole.length > 0 ? (
                                <div
                                    className={`cdb-testimonial-author-role`}
                                    dangerouslySetInnerHTML={{
                                        __html: userRole
                                    }}
                                />
                            ) : (
                                ''
                            )}
                        </div>
                    ) : (
                        ''
                    )}
                </div>
            </div>
        </div>
    );
};

export default CodebeanTestimonialsItem;
