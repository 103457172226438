import React from 'react';

const FooterGetInTouch = ({ title, data, ...props }) => {
    return (
        <div className="widget widget_text">
            <h4 className="cdb-widget-title">{title}</h4>
            <div
                className="textwidget"
                dangerouslySetInnerHTML={{ __html: data }}
            ></div>
        </div>
    );
};

export default FooterGetInTouch;
