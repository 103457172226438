import Router from 'next/router';
import { useEffect } from 'react';

/*
 * On each Next router transition, run callback.
 */
export const useRouteChange = function (callback) {
    useEffect(() => {
        const handleRouteChange = (url) => callback(url);

        Router.events.on('routeChangeComplete', handleRouteChange);

        return () => {
            Router.events.off('routeChangeComplete', handleRouteChange);
        };
    }, []);
};

/*
 * If a user clicks outside the ref element, run callback.
 */
export const useClickOutside = function (ref, callback) {
    const onClick = (ev) => {
        if (ref.current && !ref.current.contains(ev.target)) {
            callback();
        }
    };

    useEffect(() => {
        document.addEventListener('click', onClick);

        return () => {
            document.removeEventListener('click', onClick);
        };
    }, []);
};
