import React from 'react';

import VisualComposerHoverbox from '../components/visual-composer/VisualComposerHoverbox';

function VisualComposerHoverboxShortcode({content, ...props}) {
    const {
        el_class,
        hover_title,
        image,
        pageProps,
        shape,
        css,
        meta_properties
    } = props;

    return (
        <VisualComposerHoverbox
            content={content}
            elClass={el_class}
            hoverTitle={hover_title}
            imageId={image}
            pageProps={pageProps}
            content={content}
            shape={shape}
            css={css}
            meta_properties={meta_properties}
        />
    );
}

export default VisualComposerHoverboxShortcode;
