import React from 'react';

import VisualComposerAccordion from '../components/visual-composer/VisualComposerAccordion';

function VisualComposerAccordionShortcode(props) {
    return (
        <VisualComposerAccordion attr={props}>
            {props.children}
        </VisualComposerAccordion>
    );
}

export default VisualComposerAccordionShortcode;
