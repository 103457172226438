import React from 'react';

const FooterForm = ({ form = '', afterForm = '', beforeForm = '' }) => {
    return (
        <div>
            {beforeForm && (
                <div
                    className="widget widget_text"
                    style={{ paddingBottom: 0 }}
                >
                    <div className="textwidget">
                        <div
                            dangerouslySetInnerHTML={{ __html: beforeForm }}
                        ></div>
                    </div>
                </div>
            )}
            <div className="widget gform_widget">
                {form !== null && form.length > 0 ? (
                    <iframe
                        style={{ height: `120px`, width: `100%` }}
                        src={form}
                        title="Footer form"
                    ></iframe>
                ) : (
                    ''
                )}
            </div>
            {afterForm && (
                <div className="widget widget_text">
                    <div className="textwidget">
                        <div
                            className="terranova-footer-small"
                            dangerouslySetInnerHTML={{ __html: afterForm }}
                        ></div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default FooterForm;
