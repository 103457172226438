import React from 'react';

const VisualComposerSeparator = ({
    align = 'center',
    borderWidth = 1,
    color = 'grey',
    className = '',
    idName = '',
    style = 'border',
    elWidth = 100
}) => {
    return (
        <div
            id={idName && idName}
            className={`vc_separator wpb_content_element vc_separator_align_center vc_separator_no_text
                ${`vc_sep_pos_align_${align}`}
                ${`vc_sep_width_${elWidth}`}
                ${`vc_sep_color_${color}`}
                ${`vc_sep_border_width_${borderWidth}`}
                ${`vc_sep_${style}`}
                ${className}
            `}
        >
            <span className="vc_sep_holder vc_sep_holder_l">
                <span className="vc_sep_line"></span>
            </span>
            <span className="vc_sep_holder vc_sep_holder_r">
                <span className="vc_sep_line"></span>
            </span>
        </div>
    );
};

export default VisualComposerSeparator;
