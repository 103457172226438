import React from 'react';

import VisualComposerSeparatorZigZag from './../components/visual-composer/VisualComposerSeparatorZigZag';

const VisualComposerSeparatorShortcode = (props) => {
    const {
        align,
        el_border_width,
        el_class,
        el_id,
        el_width,
    } = props;


    return (
        <VisualComposerSeparatorZigZag
            align={align}
            borderWidth={el_border_width}
            className={el_class}
            idName={el_id}
            elWidth={el_width}
        />
    );
};

export default VisualComposerSeparatorShortcode;
