import { NextSeo } from 'next-seo';

import { getOptionItems } from '../lib/option';
import { getMenus } from '../lib/menus';
import {
    getBlogArchiveData,
    getPosts,
    getPaginationInfoForCategoriesPostsTags
} from '../lib/blog';
import { 
    getPage, 
    getPageMap, 
    getPages, 
    getPathIndex, 
    writePathIndices
} from '../lib/pages';

import { 
    LOCALE_CODE_FROM_LANGUAGE_CODE,
    LOCALE_TO_CANONICAL_PREFIX,
    LOCALE_TO_HREFLANG,
    TRANSLATION_LINK_OVERRIDES
} from '../util/i18n';
import { isPreviewPath, getPreviewID } from '../util/preview';
import { renderShortcodes } from '../util/shortcodes';
import { makeLinkRelative } from '../util/wordpress';

import PageLayout from '../layouts/PageLayout';
import PostLayout from '../layouts/PostLayout';
import BlogLayout from '../layouts/BlogLayout';
import NotFoundLayout from '../layouts/NotFoundLayout';
import SearchLayout from '../layouts/SearchLayout';

const viewTypeToLayoutComponent = {
    page: PageLayout,
    blog: BlogLayout,
    category: BlogLayout,
    tag: BlogLayout
};

export default function Page(props) {
    if (props.is404 || !props.viewType) {
        let pageProps = {...props};

        pageProps.title = 'Page not found';
        pageProps.template = { templateName: 'Default Layout' };
        pageProps.locale = { locale: 'en_US'};
        pageProps.translations = [];

        return <NotFoundLayout 
            pageProps={pageProps}
        />
    }

    const ContentTypeLayoutComponent =
        viewTypeToLayoutComponent[props.viewType];
    const { seo } = props;

    let metaRobotsNoindex = seo.metaRobotsNoindex;
    let metaRobotsNofollow = seo.metaRobotsNofollow;
    
    let nofollow = true;
    let noindex = true;

    if(metaRobotsNoindex === 'noindex') {
        noindex = true;
    }
    if(metaRobotsNofollow === 'nofollow') {
        nofollow = true;
    }
    
    let nextSeoProps = {
        canonical: seo.canonical,
        title: seo.title,
        description: seo.metaDesc,
        noindex: noindex,
        nofollow: nofollow,
        openGraph: {
            title: seo.opengraphTitle,
            description: seo.opengraphDescription,
            type: seo.opengraphType,
            article: {
                modifiedTime: seo.opengraphModifiedTime
            }
        }
    };

    let relativeCanonical = makeLinkRelative(nextSeoProps.canonical);
    if(TRANSLATION_LINK_OVERRIDES[relativeCanonical]) {
        nextSeoProps.canonical = process.env.NEXT_PUBLIC_SITEMAP_ROOT_URL + TRANSLATION_LINK_OVERRIDES[relativeCanonical];
    }
    
    let fallbackCanonical = nextSeoProps.canonical;
    if(!fallbackCanonical) {
        switch(props.viewType) {
            case 'page':
            case 'post':
                fallbackCanonical = process.env.NEXT_PUBLIC_SITEMAP_ROOT_URL
                    + LOCALE_TO_CANONICAL_PREFIX[props.locale.locale]
                    + props.slug;
                break;

            default:
                break;
        }

        nextSeoProps.canonical = fallbackCanonical;
    }

    if(props.translations && props.translations.length) {
        nextSeoProps.languageAlternates = props.translations.map(translation => {
            let translationHref = translation.href;

            if(TRANSLATION_LINK_OVERRIDES[translationHref]) {
                translationHref = TRANSLATION_LINK_OVERRIDES[translationHref];
            }

            return {
                hrefLang: LOCALE_TO_HREFLANG[translation.locale], 
                href: process.env.NEXT_PUBLIC_SITEMAP_ROOT_URL + translationHref 
            };
        });
       
        if(LOCALE_TO_HREFLANG[props.locale.locale] && fallbackCanonical) {
            nextSeoProps.languageAlternates.push({ hrefLang: LOCALE_TO_HREFLANG[props.locale.locale], href: fallbackCanonical });
        }
    }

    if(seo.opengraphImage) {
        nextSeoProps.openGraph.images = [
            {
                url: seo.opengraphImage.sourceUrl,
                alt: seo.opengraphImage.altText,
                width: seo.opengraphImage.mediaDetails.width,
                height: seo.opengraphImage.mediaDetails.height 
            }
        ];
    }

    if (props.viewType === 'page') {
        const { contentType, shortcodes } = props;

        if(contentType === 'post') {
            return (
                <PostLayout pageProps={props}>
                    <NextSeo {...nextSeoProps} />
                </PostLayout>
            );
        }

        return (
            <ContentTypeLayoutComponent pageProps={props}>
                <NextSeo {...nextSeoProps} />

                {renderShortcodes(shortcodes, props)}
            </ContentTypeLayoutComponent>
        );
    } else if (props.viewType === 'search') {
        return <SearchLayout results={[]} pageProps={props} />;
    } else {
        return (
            <ContentTypeLayoutComponent pageProps={props}>
                <NextSeo {...nextSeoProps} />
            </ContentTypeLayoutComponent>
        );
    }
}

export async function getStaticPaths(context) {
    const contentNodes = await getPages();
    const VALID_CONTENT_TYPES = ['page', 'post'];

    const paths = contentNodes
        .filter((contentNode) => contentNode?.uri)
        .filter((contentNode) =>
            VALID_CONTENT_TYPES.includes(contentNode?.contentType?.node?.name)
        )
        .map((contentNode) => contentNode.uri);

    paths.push('/search');
    paths.push('/fr/search');
    paths.push('/fr-fr/search');

    for(const language of ['en', 'fr']) {
        const pageInfo = await getPaginationInfoForCategoriesPostsTags(language);
        const numberPostPages = Math.ceil(pageInfo.posts.count / 10);
        if (numberPostPages >= 2) {
            for (let i = 2; i <= numberPostPages; i++) {
                paths.push(
                    language === 'en' 
                        ? `/blog/page/${i}`
                        : `/fr/blogue/page/${i}`
                    );
            }
        }

        for (const category of pageInfo.categories) {
            const numberCategoryPages = Math.ceil(category.count / 10);

            paths.push(
                language === 'en'
                    ? `/category/${category.slug}`
                    : `/fr/category/${category.slug}`
            );

            if (numberCategoryPages >= 2) {
                for (let i = 2; i <= numberCategoryPages; i++) {
                    paths.push(
                        language === 'en'
                            ? `/category/${category.slug}/page/${i}`
                            : `/fr/category/${category.slug}/page/${i}`
                    );
                }
            }
        }

        for (const tag of pageInfo.tags) {
            const numberTagPages = Math.ceil(tag.count / 10);

            paths.push(
                language === 'en'
                    ? `/tag/${tag.slug}`
                    : `/fr/tag/${tag.slug}`
            );

            if (numberTagPages >= 2) {
                for (let i = 2; i <= numberTagPages; i++) {
                    paths.push(
                        language === 'en'
                            ? `/tag/${tag.slug}/page/${i}`
                            : `/fr/tag/${tag.slug}/page/${i}` 
                    );
                }
            }
        }
    }
       
    if(process.env.NEXT_PUBLIC_BUILD_MODE) {
        await writePathIndices(paths);
    }

    return {
        paths,
        fallback: 'blocking'
    };
}

export async function getStaticProps(context) {
    /* If there is no param, we're on the site root URL */
    const uriParts = context.params?.page || ['/'];
    /* Last element is the post URI we're interested in */
    const page = uriParts[uriParts.length - 1];
    const isPreview = isPreviewPath(context);
    const postId = isPreview ? getPreviewID(context) : null;

    /*
     * We'll make determinations on the URI structure based on if this is a
     * translation, and then if the first "meaningful" element of the URI is
     * category, tag.
     */
    let viewType = 'page',
        language = 'en';
    let uriPartTypeIndex = 0;
    let locale = { locale: 'en_US' };
    let pageOffset = 0;
    const pageUri = '/' + uriParts.join('/');

    if (uriParts[0] === 'fr' || uriParts[0] === 'fr-fr') {
        /* Translation */
        uriPartTypeIndex = 1; /* ex: /fr/category/my-category */
        language = uriParts[0];
    } 
    
    if (uriParts[uriParts.length - 1] === 'search') {
        viewType = 'search';
    }

    locale.locale = LOCALE_CODE_FROM_LANGUAGE_CODE[language];

    /* Prevent checking out-of-bounds on non-taxonomic routes */
    if (uriParts.length > uriPartTypeIndex) {
        if (
            uriParts[uriPartTypeIndex] === 'category' &&
            uriParts.length > uriPartTypeIndex + 1
        ) {
            viewType = 'category';
        } else if (
            uriParts[uriPartTypeIndex] === 'tag' &&
            uriParts.length > uriPartTypeIndex + 1
        ) {
            viewType = 'tag';
        } else if (
            uriParts[uriPartTypeIndex] === 'blog' ||
            uriParts[uriPartTypeIndex] === 'blogue'
        ) {
            viewType = 'blog';
        }

        if (viewType !== 'page') {
            if (viewType === 'blog' && uriParts.length > uriPartTypeIndex + 2) {
                if (uriParts[uriPartTypeIndex + 1] === 'page') {
                    pageOffset = parseInt(uriParts[uriPartTypeIndex + 2]) - 1;
                }
            } else if (uriParts.length > uriPartTypeIndex + 3) {
                if (uriParts[uriPartTypeIndex + 2] === 'page') {
                    pageOffset = parseInt(uriParts[uriPartTypeIndex + 3]) - 1;
                }
            }
        }
    }

    /*
     * Objects which are pulled in, cached, and lived between all pages/posts:
     * Menus and site options.
     */
    /*
     * Retrieve media items and map to an object keying post_id to the media item's properties
     */
    const menus = {
        en: {}, 
        fr: {}, 
        'fr-fr': {} 
    };
    
    (await getMenus('en')).map((menu) => (menus['en'][menu.slug] = menu));
    (await getMenus('fr')).map((menu) => (menus['fr'][menu.slug] = menu));
    (await getMenus('fr-fr')).map((menu) => (menus['fr-fr'][menu.slug] = menu));

    const options = await getOptionItems();

    try {
        if(process.env.NEXT_PUBLIC_BUILD_MODE && !isPreview) { 
            const result = await getPathIndex(pageUri);

            if(result.shouldUpdate) {
                const resp = await fetch(`https://terranova.splice.website/builds/percentage/${result.percentage}`);
            }
        }
    } catch(err) {
        console.error(err);
    }

    if (viewType === 'page') {
        /* Get the page data */
        let pageData = null;

        if(!postId) {
            if(process.env.NEXT_PUBLIC_BUILD_MODE && !isPreview) {
                try {
                    const pages = await getPageMap();
                    pageData = pages[pageUri === '//' ? '/' : pageUri + '/'];
                } catch(err) {
                    console.error(err);
                    throw new Error(`Failed to retrieve ${pageUri} from cache.`);
                }
            } else {
                pageData = await getPage(page, postId);
            }
        } else {
            pageData = await getPage(page, postId);
        }

        if (
            !isPreview && (
                !pageData ||
                !pageData.contentNode ||
                !Object.keys(pageData.contentNode).length)
        ) {
            return { 
                props: { 
                    is404: true,
                    language: 'en',
                    menus: menus,
                    options: options
                }
            };
        }

        let {
            id,
            title,
            contentType: {
                node: { name: contentType }
            },
            template,
            seo,
            translations,
            slug
        } = pageData.contentNode;
        
        let props = {
            viewType,
            id,
            title,
            contentType,
            menus,
            options,
            template,
            seo,
            slug,
            language,
            locale,
            translations
        };

        if (contentType === 'page') {
            const shortcodes = JSON.parse(pageData.contentNode.shortcodesJSON);

            props = {
                ...props,
                shortcodes: shortcodes.data,
                bakeryBuilderCSS: pageData.contentNode.bakeryBuilderCSS,
                perPageFields: pageData.contentNode.perPageFields
            };
        } else if(contentType === 'post') {
            const blogArchiveData = await getBlogArchiveData(language);
            const firstFivePosts = await getPosts(null, null, language, 0, 5);

            props = {
                ...props,
                date: pageData.contentNode.date,
                content: pageData.contentNode.content,
                featuredImage: pageData.contentNode.featuredImage,
                firstCategory: pageData.contentNode.firstCategory,
                next: pageData.contentNode.next,
                previous: pageData.contentNode.previous,
                uri: pageData.contentNode.uri,
                firstFivePosts: firstFivePosts.posts,
                blogArchiveData
            };
        }

        if(isPreview) {
            return { props: props, revalidate: 1 };
        }
        
        return { props: props };
    } else {
        if (viewType === 'search') {
            return {
                props: {
                    template: { templateName: 'Search' },
                    viewType,
                    seo: {},
                    title: 'Search | Terranova Security',
                    menus,
                    language,
                    locale,
                    translations: [],
                    options
                }
            };
        } else if (viewType === 'blog') {
            const blogArchiveData = await getBlogArchiveData(language);
            const { pageInfo, posts } = await getPosts(null, null, language, pageOffset);
            const firstFivePosts = await getPosts(null, null, language, 0, 5);

            return {
                props: {
                    template: { templateName: 'Default' },
                    posts,
                    seo: {
                        title: 'Cyber Security Blog | Terranova Security'
                    },
                    pageInfo: { ...pageInfo, currentPage: pageOffset + 1 },
                    blogArchiveData,
                    viewType,
                    language,
                    locale,
                    firstFivePosts: firstFivePosts.posts,
                    translations: [],
                    title: 'Cyber Security Blog | Terranova Security',
                    menus,
                    options,
                    uri: pageUri
                }
            };
        } else if (viewType === 'tag') {
            const blogArchiveData = await getBlogArchiveData(language);
            const { pageInfo, posts, tag } = await getPosts(
                uriParts[uriPartTypeIndex + 1],
                null,
                language,
                pageOffset
            );
            const firstFivePosts = await getPosts(null, null, language, 0, 5);

            return {
                props: {
                    template: { templateName: 'Blog Archive' },
                    posts,
                    seo: {
                        title: tag.name + ' Archives'
                    },
                    pageInfo: { ...pageInfo, currentPage: pageOffset + 1 },
                    blogArchiveData,
                    firstFivePosts: firstFivePosts.posts,
                    viewType,
                    language,
                    locale,
                    title: tag.name + ' Archives',
                    tagName: tag.name,
                    tagSlug: tag.slug,
                    translations: [],
                    menus,
                    options,
                    uri: pageUri
                }
            };
        } else if (viewType === 'category') {
            const blogArchiveData = await getBlogArchiveData(language);
            const { pageInfo, posts, category } = await getPosts(
                null,
                uriParts[uriPartTypeIndex + 1],
                language,
                pageOffset
            );
            const firstFivePosts = await getPosts(null, null, language, 0, 5);

            return {
                props: {
                    template: { templateName: 'Blog Archive' },
                    posts,
                    seo: {
                        title: category.name + ' Archives'
                    },
                    pageInfo: { ...pageInfo, currentPage: pageOffset + 1 },
                    blogArchiveData,
                    firstFivePosts: firstFivePosts.posts,
                    viewType,
                    language,
                    locale,
                    title: category.name + ' Archives',
                    categoryName: category.name,
                    categorySlug: category.slug,
                    translations: [],
                    menus,
                    options,
                    uri: pageUri
                }
            };
        } else {
            return { props: { is404: true } };
        }
    }
}
