import React from 'react';
import VisualComposerPostGridItem from './VisualComposerPostGridItem';

const VisualComposerPostGrid = ({
    postType,
    maxItems,
    initLoadingAnimation,
    gridId,
    taxonomies,
    id = '',
    className = '',
    gap = '30',
    colWidth = 4,
    pageProps,
    metaProperties,
    ...props
}) => {
    
    const {results} = metaProperties;

    return (
        <div className="vc_grid-container-wrapper vc_clearfix" id={id}>
            <div
                className={`vc_grid-container vc_clearfix wpb_content_element vc_basic_grid ${className}`}
            >
                <div
                    className={`vc_grid vc_row vc_grid-gutter-${gap}px vc_pageable-wrapper vc_hook_hover`}
                >
                    <div className="vc_pageable-slide-wrapper vc_clearfix">
                        {results.map((post, i) => (
                            <VisualComposerPostGridItem
                                key={post.id}
                                colWidth={colWidth}
                                post={post}
                                pageProps={pageProps}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default VisualComposerPostGrid;
